import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ETHService} from "../../../core/services/connector.service";
import {BackendService} from "../../../core/services/backend.service";

@Component({
  selector: 'fts-simple-sign-modal',
  templateUrl: './simple-sign-modal.component.html',
  styleUrls: ['../sign-modal/sign-modal.component.css', './simple-sign-modal.component.css']
})
export class SimpleSignModalComponent {

  readonly titleModal = 'DON’T CLOSE OR RELOAD THE PAGE'
  description = '';

  transactionsCount: number = 0;
  transactionsSigned: number = 0;
  transactionsConfirmed: number = 0;

  currentStep = -1;

  get percentageTransaction(): number {
    return ((this.transactionsSigned + this.transactionsConfirmed) / (this.transactionsCount * 2)) * 100;
  }

  static open<R = any>(dialog: MatDialog, count: number, description: string = ''): MatDialogRef<SimpleSignModalComponent, R> {
    return dialog.open(SimpleSignModalComponent, {
      data:  {
        count,
        description
      }
    })
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { count: number, description: string },
    protected _dialog: MatDialog,
    protected _dialogRef: MatDialogRef<SimpleSignModalComponent>,
    protected _ETHService: ETHService,
    protected _backend: BackendService,
  ) {
    _dialogRef.addPanelClass('small-modal')
    _dialogRef.disableClose = true;
    this.transactionsCount = data?.count || 0;
    this.description = data?.description;
  }

  setTransactionsSigned(count: number) {
    this.transactionsSigned = count;
    if (count == 1) {
      this.currentStep = 0;
    }
  }

  setTransactionsConfirmed(count: number) {
    this.transactionsConfirmed = count;
    if (count === this.transactionsCount) {
      this.currentStep = 1;
    }
  }

}
