import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Character} from "../../../../core/models/ethResponse";

@Component({
  selector: 'fts-character-import-modal',
  templateUrl: './character-import-modal.component.html',
  styleUrls: ['./character-import-modal.component.css']
})
export class CharacterImportModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Character,
    protected _dialogRef: MatDialogRef<CharacterImportModalComponent>,
    private _dialog: MatDialog,
  ) {
    _dialogRef.addPanelClass('large-modal')
  }

}
