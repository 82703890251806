<div class="content" fxLayout="column">
  <span>Welcome to the Pre-Alpha Stage of The Shelter.</span>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="full-width" fxFlexOffset="16px" style="gap: 12px">
    <mat-button-toggle-group [formControl]="selectorFilter" style="flex: 0 0 270px" class="flex-width flex-grow-on-mobile">
      <mat-button-toggle checked value="all">All</mat-button-toggle>
      <mat-button-toggle value="inGame">In-game</mat-button-toggle>
      <mat-button-toggle value="pending" *ngIf="containsPending">Pending</mat-button-toggle>
    </mat-button-toggle-group>
    <span fxFlex fxHide.xs></span>
    <mat-form-field appearance="outline" color="primary" class="simple-select flex-grow-on-mobile" style="flex: 0 0 200px">
      <mat-select [formControl]="sortControl" panelClass="simple-select-panel">
        <mat-option value="0">Lower Mint</mat-option>
        <mat-option value="1">Higher Mint</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxFlexOffset="16px">
    <mat-spinner fxFlexOffset="120px" color="accent" diameter="60"
      *ngIf="isLoading; else viewList"></mat-spinner>
  </div>
  <ng-template #viewList>
    <ts-empty-view
      *ngIf="filteredList.length === 0"
      [description]="'Nothing here!'"
    ></ts-empty-view>
    <div class="container-cards">
      <fts-character-card (click)="openModal(item)" *ngFor="let item of filteredList" [item]="item"></fts-character-card>
    </div>
  </ng-template>
</div>
