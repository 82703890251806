import {Component, Inject, OnInit} from '@angular/core';
import {SignModalComponent} from "../sign-modal/sign-modal.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToastService, ToastType} from "../../../core/services/toast.service";
import {ETHService} from "../../../core/services/connector.service";
import {BackendService} from "../../../core/services/backend.service";
import {abiTheStakeContract} from "../../../core/interfaces/abitest";
import {Address, waitForTransaction} from "@wagmi/core";
import {CONFIRMATIONS_MAIN_TRANSACTION} from "../../../core/core.constants";
import {parseEther} from "viem";
import {Character, STATUS_ASSET} from "../../../core/models/ethResponse";
import {EthereumService} from "../../../core/services/ethereum.service";
import {StakingModalService} from "../../services/staking-modal.service";
import {getNetworkId} from "../../../core/utils/functions";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'fts-unstake-sign-modal',
  templateUrl: './unstake-sign-modal.component.html',
  styleUrls: ['./unstake-sign-modal.component.css', '../sign-modal/sign-modal.component.css']
})
export class UnstakeSignModalComponent extends SignModalComponent implements OnInit{

  isPreparingTransaction = false;
  character: Character;

  signList: any[] | undefined;
  hashStake: string;

  get canShowRefresh(): boolean {
    return !this.error
  }

  progressBackend = 0;
  progressConfirmation = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Character,
    protected _dialog: MatDialog,
    protected _dialogRef: MatDialogRef<UnstakeSignModalComponent>,
    private _toastService: ToastService,
    protected _ETHService: ETHService,
    protected _backend: BackendService,
    private _ethereumService: EthereumService,
    protected _stakingModalService: StakingModalService,
  ) {
    super(_dialog, _dialogRef, _ETHService, _backend)
    if (!data) {
      _toastService.open('Character not found. Retry', ToastType.ERROR);
      _dialogRef.close();
    }
    this.character = data;
  }

  ngOnInit() {
    this.refresh();
  }

  get isRefreshing(): boolean {
    return this.isPreparingTransaction;
  }

  async refresh() {
    try {
      this.isPreparingTransaction = true;
      this.error = undefined;
      this.signList = undefined;
      if (this.character.status === STATUS_ASSET.PENDING) {
        await this.pendingRefresh();
        return;
      }
      const signList = [];
      signList.push({
        abi: abiTheStakeContract,
        address: this._ETHService.stakeContract,
        args: [this.character.tokenId],
        confirmations: CONFIRMATIONS_MAIN_TRANSACTION,
        functionName: 'requestUnstake',
        value: parseEther(environment.fee_unstake),
      })
      this.signList = signList;
      this.unstake(this.character, signList);
    } catch(e) {
      this.error = {message: 'Error while preparing transaction', callback: () => this.refresh()}
    } finally {
      this.isPreparingTransaction = false;
    }
  }

  async pendingRefresh() {
    try {
      const hash = await this._stakingModalService.getHashCurrentUnstaking(this.character);
      if (hash) {
        this.isPreparingTransaction = false;
        this.signList = [];
        this.transactionsCount = 1;
        this.setTransactionsSigned(1);
        this.setTransactionsConfirmed(1);
        this.currentStep = 1;
        await this.callBackend(hash)
      }
    } catch (e) {
      this._toastService.open('Can\'t retrieve your unstake transaction', ToastType.ERROR);
    }
  }

  async unstake(item: Character, signList: any[]): Promise<void> {
    const result = await this._ETHService.signTransaction(this, signList);
    if (!result?.success) {
      this._dialogRef.close();
      return;
    }
    this.hashStake = result.hash_array[result.hash_array.length -1];
    this.callBackend(this.hashStake);
  }

  async callBackend(hashStake: string) {
    this.error = undefined;
    const resultBackend = await this._backend.unstake(this.character.tokenId, hashStake, () => {
      this.progressBackend = 50;
    })
    if (!resultBackend.success) {
      this.error = {message: resultBackend.errorMessage, callback: () => this.callBackend(this.hashStake)}
      return;
    }
    this.progressBackend = 100;
    this.currentStep = 2;

    const txHash = resultBackend.data.txHash;
    this.waitConfirmationUnstake(txHash);
  }

  async waitConfirmationUnstake(hashStake: Address) {
    try {
      this.error = undefined;
      const transactionReceipt = await waitForTransaction({
        chainId: getNetworkId().id,
        hash: hashStake,
        confirmations: CONFIRMATIONS_MAIN_TRANSACTION,
      })
      this.progressConfirmation = 100;
      this.currentStep = 3;
      await new Promise(resolve => setTimeout(resolve, 1500));
      this._dialogRef.close(true);
    } catch (e) {
      this.error = {message: e?.message || e?.error || 'Error...', callback: () => this.waitConfirmationUnstake(hashStake)}
    }
  }

}
