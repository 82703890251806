import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {CharacterInfoModalComponent} from "./modals/character-info-modal/character-info-modal.component";
import {EthereumService} from "../../core/services/ethereum.service";
import {FormControl} from "@angular/forms";
import {Character, STATUS_ASSET} from "../../core/models/ethResponse";
import {SessionQuery} from "../../core/stores/session/session.query";
import {ToastService, ToastType} from "../../core/services/toast.service";
import {AutoUnsubscribe} from "ngx-auto-unsubscribe-decorator";

@Component({
  selector: 'fts-characters',
  templateUrl: './characters.component.html',
  styleUrls: ['./characters.component.scss']
})
export class CharactersComponent implements OnInit {

  isLoading = false;
  private list: Character[] = [];
  filteredList: Character[] = [];
  containsPending = false;

  selectorFilter = new FormControl();
  sortControl = new FormControl('0');

  isFirstRefresh = true;

  @AutoUnsubscribe()
  isLogged$ = this._session.isLogged$;

  constructor(
    private _modal: MatDialog,
    private _session: SessionQuery,
    private _ethereumService: EthereumService,
    private _toastService: ToastService,
  ) {
    this.selectorFilter.valueChanges.subscribe(value => this.refreshFilterList())
    this.sortControl.valueChanges.subscribe(value => this.refreshFilterList())
    this.isLogged$.subscribe(isLogged => this.refresh());
  }

  ngOnInit() {
  }

  async refresh() {
    this.isLoading = true;
    try {
      this.list = await this._ethereumService.getUserAssets();
    } catch (e) {
      this.list = [];
      this._toastService.open(e?.shortMessage ?? e?.message ?? e?.errorMessage ?? 'Error get user assets', ToastType.ERROR);
    }

    this.containsPending = this.list.some(i => i.status === STATUS_ASSET.PENDING);
    if (this.containsPending && this.isFirstRefresh) {
      this.isFirstRefresh = false;
      this.selectorFilter.setValue('pending')
    }
    if (!this.containsPending && this.selectorFilter.value === 'pending') {
      this.selectorFilter.setValue('all');
    }
    this.refreshFilterList();
    this.isLoading = false;
  }

  private refreshFilterList() {
    let list: Character[] = [];
    if (this.selectorFilter.value === 'inGame') {
      list = this.list.filter(c => c.status === STATUS_ASSET.IN_GAME);
    } else if (this.selectorFilter.value === 'pending') {
      list = this.list.filter(c => c.status === STATUS_ASSET.PENDING);
    } else {
      list = this.list;
    }
    if (this.sortControl.value?.toString() === '1') {
      list = list.sort((a, b) => {
        return b.mint - a.mint
      })
    } else {
      list = list.sort((a, b) => {
        return a.mint - b.mint
      })
    }
    this.filteredList = list;
  }

  async openModal(item: Character): Promise<void> {
    this._modal.open(CharacterInfoModalComponent, {data: item}).afterClosed()
      .subscribe(refresh => {
        if (refresh) {
          this.refresh();
        }
      })
  }

}
