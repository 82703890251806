import {Component, OnDestroy, OnInit} from '@angular/core';
import {ETHService} from "../../core/services/connector.service";
import {SessionQuery} from "../../core/stores/session/session.query";
import {Router} from "@angular/router";
import {AutoUnsubscribe} from "ngx-auto-unsubscribe-decorator";

@Component({
  selector: 'fts-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{

  @AutoUnsubscribe()
  isLogged$ = this._session.isLogged$;

  constructor(
    private _auth: ETHService,
    private _session: SessionQuery,
    private _router: Router,
  ) {
    this.isLogged$.subscribe(isLogged => {
      if (isLogged) {
        this._router.navigate(['/']);
      }
    });
  }

  ngOnInit() {
    this.asdlfj();
  }

  async asdlfj() {
    //await new Promise(resolve => setTimeout(resolve, 5000));
    this.startParticles()
  }

  doLogin() {
    this._auth.connect();
  }

  private startParticles() {
    let url = "assets/js/my-particels.js";  //external script url
    let head = document.getElementsByTagName("head")[0];
    let scrs = head.getElementsByTagName("script");
    for(let i=0;i<scrs.length; i++){
      let scr = scrs[i];

      if(scr.outerHTML.indexOf(url) >0){
        //alert(scr.outerHTML);
        head.removeChild(scr);
      }
    }
    let script = document.createElement("script");
    script.src = url;
    script.type ="text/javascript";
    script.async = true;
    script.defer = true;
    head.appendChild(script);
  }

  ngOnDestroy() {
    let url = "assets/js/my-particels.js";  //external script url
    let head = document.getElementsByTagName("head")[0];
    let scrs = head.getElementsByTagName("script");
    for(let i=0;i<scrs.length; i++){
      let scr = scrs[i];

      if(scr.outerHTML.indexOf(url) >0){
        //alert(scr.outerHTML);
        head.removeChild(scr);
      }
    }
  }

}
