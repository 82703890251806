<div class="container full-width" fxLayout="column" fxLayoutAlign="center center">
  <span class="title">{{titleModal}}</span>
  <span class="subtitle" fxFlexOffset="10px">Please sign the transactions, it may take a few minutes!</span>

  <div class="full-width" fxLayout="column" fxLayoutAlign="center center" *ngIf="isPreparingTransaction; else viewList">
    <mat-spinner fxFlexOffset="120px" color="accent" diameter="60"></mat-spinner>
  </div>
  <ng-template #viewList>
    <div class="container-steps" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center" fxFlexOffset="80px" *ngIf="signList">
      <fts-step-circle
        [position]="0"
        [currentStep]="currentStep"
        [label]="'Transaction Signed'"
        [subtitle]="transactionsSigned + '/' + transactionsCount"
        [percentageProgress]="percentageTransaction"
        [canShowRefresh]="canShowRefresh"
      ></fts-step-circle>
      <fts-step-circle
        [position]="1"
        [currentStep]="currentStep"
        [label]="'Transaction Confirmed'"
        [subtitle]="transactionsConfirmed + '/' + transactionsCount"
        [percentageProgress]="progressBackend"
        [canShowRefresh]="canShowRefresh"
      ></fts-step-circle>
      <fts-step-circle
        [position]="2"
        [currentStep]="currentStep"
        [label]="'Server Confirmation'"
        [percentageProgress]="progressConfirmation"
        [canShowRefresh]="canShowRefresh"
      ></fts-step-circle>
      <fts-step-circle
        [position]="3"
        [currentStep]="currentStep"
        [label]="'Confirmation'"
        [showProgress]="false"
        [canShowRefresh]="canShowRefresh"
      ></fts-step-circle>
    </div>
  </ng-template>
  <div class="full-width" fxLayout="column" fxLayoutAlign="center center" *ngIf="!isRefreshing && error" fxFlexOffset="20px" fxLayoutGap="12px">
    <span class="error-text">{{error.message}}</span>
    <button class="flat-button orange-button-new button-reload" *ngIf="error" (click)="error.callback()">RELOAD</button>
  </div>
  <div class="image-close" (click)="closeDialog()">
    <img src="/assets/icons/ic-close.svg" class="full-width" />
  </div>

</div>
