import {Injectable} from "@angular/core";
import {BaseService} from "../../core/services/base.service";
import {HttpClient} from "@angular/common/http";
import {LogService} from "../../core/services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SessionQuery} from "../../core/stores/session/session.query";
import {GameConfigQuery} from "../../core/stores/gameconfig/gameconfig.query";
import {SessionStore} from "../../core/stores/session/session.store";
import {FireService} from "../../core/services/fire.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {WaxChainService} from "../../core/services/wax-chain.service";
import {ToastService} from "../../core/services/toast.service";
import {ETHService} from "../../core/services/connector.service";
import {Character} from "../../core/models/ethResponse";
import {EthereumService} from "../../core/services/ethereum.service";
import {decodeFunctionData} from "viem";
import {abiTheStakeContract} from "../../core/interfaces/abitest";


@Injectable({providedIn: 'root'})
export class StakingModalService extends BaseService {

  constructor(
    protected _http: HttpClient,
    protected _log: LogService,
    protected _snack: MatSnackBar,
    protected _session: SessionQuery,
    private _gameConfig: GameConfigQuery,
    private _sessionStore: SessionStore,
    private _fireService: FireService,
    private _fireStore: AngularFirestore,
    private _waxChainService: WaxChainService,
    private _toastService: ToastService,
    private _ethereumService: EthereumService,
    private _eth: ETHService,
  ) {
    super(_http, _session, _log, _snack);
  }

  async getHashCurrentStaking(character: Character): Promise<string | undefined> {
    const result = await this._ethereumService.getLastStakeTransactions();
    const list = result.result.transfers.reverse();
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.tokenId === character.tokenId) {
        return item.hash;
      }
    }
    return undefined;
  }

  async getHashCurrentUnstaking(character: Character): Promise<string | undefined> {
    const result = await this._ethereumService.getLastUnstakeTransactions();
    for (let item of result.result.transfers) {
      const detailTx = await this._ethereumService.getTransactionInfoByHash(item.hash);
      const decodedData: {functionName, args} = await decodeFunctionData({
        abi: abiTheStakeContract,
        data: detailTx.result.input
      })
      if (decodedData.functionName === 'requestUnstake' && decodedData.args.toString() === character.mint.toString()) {
        return item.hash;
      }
    }
    return undefined;
  }


}
