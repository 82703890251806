import {AtomicAsset} from "./blockchain/atomicAsset";

export interface Blueprint {
  asset_id: string,
  template_id: string,
  name: string,
  subtitle: string,
  //imageUrl: string,
  rarity: string,
  cost: number, //PVERSE
  resources: ResourceObj,
  result_template_id: string,
}

export interface Booster {
  asset_id: string,
  template_id: string,
  name: string,
  rarity: string,
  bonus: number
}

export interface ConfigWeapon {
  template_id: string,
  type: string,
  damage: { value1: number, value0: number },
  damage_prog: { value1: number, value0: number },
  range: { value1: number, value0: number },
  range_prog: { value1: number, value0: number },
  rate: { value1: number, value0: number },
  rate_prog: { value1: number, value0: number },
  crit: { value1: number, value0: number },
  crit_prog: { value1: number, value0: number },
  crit_damage: { value1: number, value0: number },
  crit_damage_prog: { value1: number, value0: number },
}

export interface ConfigWeaponWithData extends ConfigWeapon{
  template_id: string,
  name: string,
  //imageUrl: string,
  rarity: string,
}

export interface AirdropInfo {
  timestamp_id_res_0: number,
  timestamp_id_res_1: number,
  timestamp_id_res_2: number,
  resources: ResourceObj
}

export interface ConfigBlueprint {
  template_id: string,
  result_template_id: string,
  cost: number, //PVERSE
  resources: ResourceObj
}

export interface UserInfo {
  user: string,
  level: number,
  exp: number,
  balance: number,
  resources: ResourceObj,
  shop_ids: number[],
  airdrop_timestamps: { id_res: number, timestamp: number }[],
  equipped_ranged: string,
  equipped_melee: string,
}

export interface GetUserResponse {
  pfp_id: number,
  wax_address: string,
  level: number,
  race: number,
  balance: number,
  resources: ResourceObj,
  base_hp: number,
  exp: number,
  next_exp: number,
  equipped_ranged: Weapon,
  equipped_melee: Weapon,
}

export interface ConfReroll {
  level: number,
  cost: number,
  resources: ResourceObj
}

export interface ConfAirdrop {
  airdrop_id: number,
  label: string,
  resource_id: number,
  resource_amount: number
}

export interface Safebit {
  asset_id: string,
  template_id: string,
  name: string,
  //imageUrl: string,
  //rarity: string,
}

export interface Weapon {
  asset_id: string,
  template_id: string,
  name: string,
  rarity: string,
  category: string;
  type: 'ranged' | 'melee',
  stats: {
    level: number,
    rate: number,
    range: number,
    damage: number,
    crit_chance: number,
    crit_damage: number,
  },
  range_stats: {
    rate: RangeStat,
    range: RangeStat,
    damage: RangeStat,
    crit_chance: RangeStat,
    crit_damage: RangeStat,
  } | null,
  outcome_resources: ResourceObj | null
}

/**
 * funzione per parsare AtomicAsset in weapon
 *
 * @param asset
 * @param configWeapons, se vengono passate inserisce l'oggeto range_stats
 */
export function parseWeapon(asset: AtomicAsset, configWeapons: ConfigWeapon[] | undefined = undefined): Weapon {
  const weaponLevel = parseInt((asset.immutable_data.level || 0).toString());
  const templateId = asset.template.template_id.toString();
  return {
    asset_id: asset.asset_id.toString(),
    template_id: templateId,
    name: asset.name.split('|')[0].trim(),
    category: asset.data.category,
    type: asset.data.type,
    rarity: asset.data.rarity,
    stats: {
      level: weaponLevel,
      rate: asset.mutable_data.rate || 0,
      range: asset.mutable_data.range || 0,
      damage: asset.mutable_data.damage || 0,
      crit_chance: asset.mutable_data.crit_chance || 0,
      crit_damage: asset.mutable_data.crit_damage || 0,
    },
    range_stats: configWeapons ? getStatRange(templateId, weaponLevel, configWeapons) : undefined,
    outcome_resources: undefined
  }
}

function getStatRange(template_id: string, level: number, configWeapons: ConfigWeapon[]) {
  const config = configWeapons.find(c => c.template_id === template_id);

  function getRange(level: number, stat: { value1: number, value0: number }, stat_prog: { value1: number, value0: number }): RangeStat {
    const damage_min = stat.value0 + (level * stat_prog.value0);
    const damage_max = stat.value0 + (level * stat_prog.value1);
    return {min: damage_min, max: damage_max};
  }

  return {
    damage: getRange(level, config.damage, config.damage_prog),
    rate: getRange(level, config.rate, config.rate_prog),
    range: getRange(level, config.range, config.range_prog),
    crit_chance: getRange(level, config.crit, config.crit_prog),
    crit_damage: getRange(level, config.crit_damage, config.crit_damage_prog),
  }
}

export interface RangeStat {
  min: number,
  max: number
}

export interface ResourceObj {
  water: number,
  wood: number,
  metal: number
}
