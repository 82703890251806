import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'ts-empty-view',
  templateUrl: './empty-view.component.html',
  styleUrls: ['./empty-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyViewComponent implements OnInit, OnChanges {

  private readonly defaultImage = '/assets/images/empty-illustration.png';

  imageUrl = '/assets/images/empty-view-illustration.png'

  @Input() title: string | undefined | null = undefined;
  @Input() image: string | undefined | null = undefined;
  @Input() description: string = '';
  @Input() classStyle: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.image || changes.classStyle) {
      this.imageUrl = this.image ?? this.defaultImage;
    }
  }

}
