interface NFTAlchemyResponse {
  ownedNfts: any[],
  totalCount: number,
  blockHash: string,
}

export interface Character {
  tokenId: string,
  title: string,
  imageUrl: string,
  status: STATUS_ASSET,
  pending_type: PADDING_TYPE | undefined,
  mint: number,
  race: string,
  wax_address: string | undefined,
}

export function getRaceFromAlchemyItem(rawResponse: any) {
  const attributes: {value: string, trait_type: string}[] = rawResponse?.raw?.metadata?.attributes ?? [];
  return attributes.find(item => item.trait_type === 'RACE')?.value ?? '--';
}

export enum STATUS_ASSET {
  IN_WALLET,
  IN_GAME,
  PENDING,
}

export enum PADDING_TYPE {
  STAKE,
  UNSTAKE,
}

export interface UnstakingItem {
  mint_id: number,
  isUnstaking: boolean
}

export interface BaseAlchemyTransfer<ITEM> {
  "jsonrpc": string,
  "id": number,
  "result": {
    "transfers": ITEM[]
  }
}

export interface BaseAlchemyResult<ITEM> {
  "jsonrpc": string,
  "id": number,
  "result": ITEM
}
