import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UtilsModule} from "../../core/modules/utils.module";
import {RouterModule} from "@angular/router";
import {ToolbarComponent} from "../components/toolbar/toolbar.component";
import {ToolbarExtComponent} from "../components/toolbar-ext/toolbar-ext.component";
import {EmptyViewComponent} from "../components/empty-view/empty-view.component";
import {FooterComponent} from "../components/footer/footer.component";
import {LoginButtonComponent} from "../components/login-button/login-button.component";

const COMPONENTS: any[] = [
  ToolbarComponent,
  ToolbarExtComponent,
  EmptyViewComponent,
  FooterComponent,
  LoginButtonComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    UtilsModule,
    RouterModule,
  ],
  exports: [
    ...COMPONENTS,
    UtilsModule,
  ],
})
export class SharedModule { }
