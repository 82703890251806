export const environment = {
  production: true,
  host: 'api-wax.tacocrypto.io',
  waxEndpoint: 'https://api-wax.tacocrypto.io',
  contract: 'acr.taco',
  transferContract: 't.taco',
  collectionName: 'taco',
  atomicApi: 'atomic-wax.tacocrypto.io',
  firebaseUrl: 'https://europe-west1-polyverse-33c78.cloudfunctions.net/',
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4', // prod
  chainIsInTest: false,
  regions: ['North Europe', 'East Us', 'Central India', 'Japan East'],
  imageGateway: 'https://ipfs.tacocrypto.io/ipfs/',
  etherscanUrl: 'https://etherscan.io/',
  alchemyApiKey: 'QZ8oIXKfsdrju8W62d6CV4iQvU8fjEMp',
  alchemyUrl: 'https://eth-mainnet.g.alchemy.com',
  dropContract: '0xDFFb5D0DA1e92c14595D30f1eCFEa5706112D267',
  preStakeContract: '0x5931418b0e40cfe30129df700d7518F4C9f50B89',
  stakeContract: '0xB0926cDb57e827ED0391099dc7c8D2Dc64DAB1bb',
  airdrop_cooldown: 3600,
  craft_prog: 0.1,
  fee_unstake: '0.000000000001',
  confirmations_main_transaction: 10
};
