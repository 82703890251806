import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'obscureAddress'})
export class ObscureAddressPipe implements PipeTransform {

  transform(value?: string | null): string {
    if (value === null || value === undefined) {
      return '';
    }
    return value.slice(0, 7) + '..' + value.slice(-7);
  }
}
