<div id="footer" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.xs="column" fxLayoutGap.xs="8px">
  <img src="/assets/icons/ic-polyverse-footer.png" width="60" height="60" />
  <a href="https://polyverse.gg/" class="site-link">©2024 polyverse.gg</a>
  <span fxFlex fxHide.xs></span>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <a href="https://polyverse.gg/termsconditions/" target="_blank">
      <div class="round">
        <img src="/assets/icons/ic-terms-footer.png" class="full-size">
      </div>
    </a>
    <a href="https://twitter.com/0xPolyverse" target="_blank">
      <div class="round">
        <img src="/assets/icons/ic-twitter-footer.png" class="full-size">
      </div>
    </a>
    <a href="https://discord.gg/entertheshelter" target="_blank">
      <div class="round">
        <img src="/assets/icons/ic-discord-footer.png" class="full-size">
      </div>
    </a>
  </div>
</div>
