import {Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {ETHService} from "../../../core/services/connector.service";
import {SessionQuery} from "../../../core/stores/session/session.query";
import {FireService} from "../../../core/services/fire.service";
import {BackendService} from "../../../core/services/backend.service";
import {AutoUnsubscribe} from "ngx-auto-unsubscribe-decorator";

@Component({
  selector: 'fts-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @AutoUnsubscribe()
  addressETH$ = this._sessionQuery.addressETH$;

  @Input() showLinks = true;

  showMenu = false;
  showMobileMenu = false;

  constructor(
    protected _auth: ETHService,
    private eRef: ElementRef,
    private _sessionQuery: SessionQuery,
    private _fireBase: FireService,
    private _backend: BackendService
  ) { }

  ngOnInit() {
    this.loginAnonymous();
  }

  private async loginAnonymous(): Promise<void> {
    await this._fireBase.anonymousLogin();
  }

  async login(): Promise<void> {
    await this._auth.connect();
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.showMenu = false;
      this.showMobileMenu = false;
    }
  }

  openMenu(): void {
    this.showMenu = true;
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  closeMenu(): void {
    this.showMenu = false;
  }


}
