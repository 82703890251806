import {Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Polyverse';
  showFooter = true;

  constructor(
    private _router: Router,
  ) {
    this.setRouterBoolean(_router.url);
    this._router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.setRouterBoolean(e?.urlAfterRedirects ?? e.url);
      }
    });
  }

  private setRouterBoolean(url: string) {
    this.showFooter = !url?.startsWith('/game');
  }

}
