import {throwError} from "rxjs";
import * as Moment from 'moment';
import {environment} from "../../../environments/environment";
import {mainnet, sepolia} from "viem/chains";

export function handleError(response: any, store: any) {
    store.setError(response.error.errorMessage);
    return throwError(response);
}

export function toMoment(date?: string): Moment.Moment {
    return date ? Moment(date) : Moment();
}

export const toBoolean = (value: string | number | boolean): boolean =>
  [true, 'true', 'True', 'TRUE', '1', 1].includes(value);

export function convertNumberToHex256(value: number): string {
  let stringHex = value.toString(16);
  while (stringHex.length < 64) {
    stringHex = "0" + stringHex;
  }
  stringHex = "0x" + stringHex;
  return stringHex;
}

export enum ResourceType {
  WATER = 0,
  WOOD = 1,
  METAL = 2
}

export function getNetworkId(): any {
  return (!environment.production && environment.chainIsInTest) ? sepolia : mainnet;
}

export function getImageAsset(assetOrTemplate?: any): string | null {
  const template = assetOrTemplate.template || assetOrTemplate;
  const img = template.immutable_data?.static_img || template.immutable_data?.static_img ||
    template.immutable_data?.img || template.immutable_data?.image || assetOrTemplate.data?.img || assetOrTemplate.data?.image;
  if (!img) {
    return null;
  }
  if (img.startsWith('http')) {
    return img;
  }
  return environment.imageGateway + img;
}

export interface StatConfigItem {
  gun_damage: number,
  melee_damage: number,
  rifle_damage: number,
  range_damage: number,
  crit_damage: number,
  speed: number,
  hp: number,
}

export function getBonusStats() {
  return {
    human: {
      gun_damage: 0,
      melee_damage: 0,
      rifle_damage: 0,
      range_damage: 0,
      crit_damage: 0,
      speed: 0,
      hp: 0,
    },
    mutant: {
      gun_damage: 15,
      melee_damage: 0,
      rifle_damage: -10,
      range_damage: 0,
      crit_damage: 0,
      speed: 0,
      hp: -5,
    },
    cyborg: {
      gun_damage: 15,
      melee_damage: -10,
      rifle_damage: 0,
      range_damage: 0,
      crit_damage: -10,
      speed: 0,
      hp: 0,
    },
    alien: {
      gun_damage: 0,
      melee_damage: -10,
      rifle_damage: 0,
      range_damage: 10,
      crit_damage: 0,
      speed: 10,
      hp: -10,
    },
    ape: {
      gun_damage: -10,
      melee_damage: 10,
      rifle_damage: -10,
      range_damage: 0,
      crit_damage: 0,
      speed: -10,
      hp: 10,
    },
  }
}

export interface ResourceConfigItem {
  water: number,
  metal: number,
  wood: number,
}

export function getBonusReward() {
 return {
    human: {
      water: 5,
      metal: 3,
      wood: 2,
    },
    mutant: {
      water: 2,
      metal: 3,
      wood: 5,
    },
    cyborg: {
      water: 0,
      metal: 10,
      wood: 0,
    },
    alien: {
      water: 10,
      metal: 0,
      wood: 0,
    },
    ape: {
      water: 0,
      metal: 0,
      wood: 10,
    },
  }
}
