<div class="content" fxLayout="column">
  <span>Welcome to the Pre-Alpha Stage of The Shelter.</span>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="full-width" fxFlexOffset="16px" style="gap: 12px">
    <mat-button-toggle-group style="flex: 0 0 270px" class="flex-width flex-grow-on-mobile">
      <mat-button-toggle checked>All</mat-button-toggle>
      <mat-button-toggle>In-game</mat-button-toggle>
    </mat-button-toggle-group>
    <span fxFlex fxHide.xs></span>
    <mat-form-field appearance="outline" color="primary" class="simple-select flex-grow-on-mobile" style="flex: 0 0 200px">
      <mat-select [formControl]="sortControl" panelClass="simple-select-panel">
        <mat-option value="0">Lower Mint</mat-option>
        <mat-option value="1">Higher Mint</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row wrap" class="characters-rows" fxFlexOffset="16px" *ngIf="false">
  </div>
</div>
