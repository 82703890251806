import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BaseService} from "./base.service";
import {SessionStore} from "../stores/session/session.store";
import {SessionQuery} from "../stores/session/session.query";
import {LogService} from "./log.service";
import {environment} from "../../../environments/environment";
import {GameConfigQuery} from "../stores/gameconfig/gameconfig.query";
import {FireService} from "./fire.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ETHService} from "./connector.service";
import {firstValueFrom} from "rxjs";
import {ToastService, ToastType} from "./toast.service";
import {
  AirdropInfo,
  Blueprint,
  Booster,
  ConfigBlueprint,
  ConfigWeapon,
  ConfigWeaponWithData,
  ConfReroll,
  GetUserResponse,
  parseWeapon,
  RangeStat,
  ResourceObj,
  Safebit,
  UserInfo,
  Weapon,
} from "../models/crafting";
import {
  AuthWaxRow,
  ConfBlueprintRow,
  ConfBoosterRow,
  ConfRerollRow,
  ConfWeaponRow,
  ShopRow,
  TupleWaxTable,
  UserRow
} from "../models/chainResponse";
import {ResourceType} from "../utils/functions";
import {
  CRAFT_COST_PROGRESSION,
  MAX_LEVEL_PFP,
  PERCENTAGE_DISMANTLE_RECEIVE,
  PVERSE_TOKEN_PRECISION
} from "../core.constants";

@Injectable({providedIn: 'root'})
export class WaxChainService extends BaseService {

  constructor(
    protected _http: HttpClient,
    protected _log: LogService,
    protected _snack: MatSnackBar,
    protected _session: SessionQuery,
    private _gameConfig: GameConfigQuery,
    private _sessionStore: SessionStore,
    private _fireService: FireService,
    private _fireStore: AngularFirestore,
    private _toastService: ToastService,
    private _eth: ETHService,
  ) {
    super(_http, _session, _log, _snack);
    this.basePath = environment.waxEndpoint;
  }

  contractPolyverse(): string {
    return 'g.polyverse';
  }

  async getAssetsForCraft(pfp_id: number, waxAddress: string) {
    const configBooster = await this.getConfigBoosters();
    const configBlueprints = await this.getConfigBlueprint();
    const configWeapons = await this.getConfigWeapons();
    const template_ids = [
      ...configBooster.map(i => i.template_id),
      ...configBlueprints.map(i => i.template_id),
    ]
    const atomicAssets = await firstValueFrom(this.getOwnedAssetsByTemplateIds(waxAddress, template_ids));
    const boosters: Booster[] = [];
    const blueprints: Blueprint[] = [];
    atomicAssets.forEach(asset => {
      const booster = configBooster.find(b => b.template_id.toString() === asset.template.template_id.toString());
      if (booster) {
        boosters.push({
          asset_id: asset.asset_id,
          template_id: booster.template_id.toString(),
          name: asset.name,
          rarity: asset.immutable_data.rarity || asset.data.rarity,
          bonus: booster.bonus,
        })
        return;
      }
      const blueprint = configBlueprints.find(b => b.template_id === asset.template.template_id.toString());
      if (blueprint) {
        const weaponConfig = configWeapons.find(b => b.template_id === blueprint.result_template_id);
        blueprints.push({
          asset_id: asset.asset_id,
          template_id: blueprint.template_id,
          name: asset.name.split('|')[0].trim(),
          subtitle: weaponConfig.type,
          rarity: asset.immutable_data.rarity || asset.data.rarity,
          result_template_id: blueprint.result_template_id,
          cost: blueprint.cost / 100,
          resources: blueprint.resources,
        })
        return;
      }
    });
    return {
      craft_multiplier: environment.craft_prog,
      blueprint_list: blueprints,
      booster_list: boosters,
      config_weapons: await this.getWeaponConfig(configWeapons),
    }
  }

  async getConfigBlueprint(): Promise<ConfigBlueprint[]> {
    const result: ConfBlueprintRow[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'confbluep');
    return result.map(row => {
      return {
        template_id: row.template_id.toString(),
        result_template_id: row.result_template_id.toString(),
        cost: parseFloat(row.cost.toString()),
        resources: this.convertResourceToObj(row.resources)
      }
    });
  }

  async getAuthUser(pfpId: number): Promise<AuthWaxRow | undefined> {
    if (!pfpId) return undefined;
    const result: AuthWaxRow[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'auth', 1, pfpId, pfpId);
    return result[0];
  }

  private async getUserRow(waxAddress: string): Promise<UserRow> {
    const result: UserRow[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'users', 1, waxAddress, waxAddress);
    if (result.length === 0) {
      throw Error('User not found. Retry');
    }
    return result[0];
  }

  async getUser(waxAddress: string): Promise<UserInfo> {
    const userRow = await this.getUserRow(waxAddress);
    return {
      user: userRow.user,
      level: parseInt(userRow.level.toString(), 10),
      exp: parseInt(userRow.exp.toString(), 10),
      balance: parseInt(userRow.balance.toString(), 10) / 100,
      resources: this.convertResourceToObj(userRow.resources),
      shop_ids: userRow.shop_ids,
      airdrop_timestamps: userRow.airdrop_timestamps.map(t => ({id_res: t.first, timestamp: (t.second + environment.airdrop_cooldown)})),
      equipped_melee: userRow.equipped_melee.toString(),
      equipped_ranged: userRow.equipped_ranged.toString(),
    }
  }

  async getUserWithWeapons(waxAddress: string, pfpId: number): Promise<GetUserResponse> {
    const auth = await this.getAuthUser(pfpId);
    if (!auth) {
      throw Error('pfp not found on auth: ' + pfpId);
    }
    const userRow = await this.getUserRow(auth.wax_address);
    const userLevel = parseInt(userRow.level.toString(), 10);
    const exp = parseInt(userRow.exp.toString(), 10);

    const atomicAssets = await firstValueFrom(this.getAssetsByIds([userRow.equipped_ranged.toString(), userRow.equipped_melee.toString()]));
    const rangedWeapon = parseWeapon(atomicAssets.find(asset => asset.asset_id.toString() === userRow.equipped_ranged.toString()));
    const meleeWeapon = parseWeapon(atomicAssets.find(asset => asset.asset_id.toString() === userRow.equipped_melee.toString()));

    return {
      pfp_id: pfpId,
      wax_address: waxAddress,
      level: userLevel,
      race: auth.race,
      balance: parseInt(userRow.balance.toString(), 10) / 100,
      resources: this.convertResourceToObj(userRow.resources),
      base_hp: WaxChainService.calculateUserHp(userLevel),
      exp: exp,
      next_exp: WaxChainService.calculateNextExperience(userLevel, exp),
      equipped_ranged: rangedWeapon,
      equipped_melee: meleeWeapon,
    }
  }

  private static calculateUserHp(level: number): number {
    if (level > MAX_LEVEL_PFP) {
      level = MAX_LEVEL_PFP;
    }
    return 100 + ((level - 1) * 5);
  }

  static calculateNextExperience(level: number, currentExp: number): number {
    if (level > MAX_LEVEL_PFP) {
      return currentExp;
    }
    return Math.trunc(100 * Math.pow(1.25, (level+1)-2));
  }

  async getShop(waxAddress: string): Promise<any[]> {
    const user = await this.getUser(waxAddress);
    const shopConfigs: ShopRow[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'shop');

    const shopUserResult = [];
    user.shop_ids.forEach(shop_id => {
      const shopRow = shopConfigs.find(shopRow => shopRow.id == shop_id);
      if (shopRow) {
        shopUserResult.push({
          id: shopRow.id,
          title: shopRow.label,
          subtitle: 'Subtitle example',
          rarity: null, //todo aggiungere le rarity (campo opzionale, non tutti gli items hanno la rarity)
          price: parseInt(shopRow.price.toString(), 10) / 100,
          imageUrl: (environment.imageGateway + (shopRow.img || 'QmeGov1EqciBg3QgesEpUznNdciyBoKXEy3xvVrXgdTFiL')),
        })
      }
    })
    return shopUserResult;
  }

  async getConfReroll(): Promise<ConfReroll[]> {
    const result: ConfRerollRow[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'confreroll');
    return result.map(row => {
      return {
        level: row.level,
        cost: parseInt(row.cost.toString(), 10) / 100,
        resources: this.convertResourceToObj(row.resources),
      }
    })
  }

  async getAssetsForReroll(pfp_id: number, waxAddress: string) {
    const configRerolls = await this.getConfReroll();
    const configSafebit: { template_id: string | number }[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'confsafebit');
    const configWeapons = await this.getConfigWeapons();
    const template_ids = [
      ...configSafebit.map(i => i.template_id.toString()),
      ...configWeapons.map(i => i.template_id),
    ]
    const atomicAssets = await firstValueFrom(this.getOwnedAssetsByTemplateIds(waxAddress, template_ids));
    const safebits: Safebit[] = [];
    const weapons: Weapon[] = [];
    atomicAssets.forEach(asset => {
      const safebit = configSafebit.find(b => b.template_id.toString() === asset.template.template_id.toString());
      if (safebit) {
        safebits.push({
          asset_id: asset.asset_id,
          template_id: safebit.template_id.toString(),
          name: asset.name,
        })
        return;
      }
      const weapon = configWeapons.find(b => b.template_id === asset.template.template_id.toString());
      if (weapon) {
        weapons.push(parseWeapon(asset, configWeapons));
      }
    });
    return {
      safebit_list: safebits,
      weapon_list: weapons,
      config_reroll: configRerolls
    }
  }

  //inventory
  async getUserWeapons(pfp_id: number, waxAddress: string): Promise<Weapon[]> {
    const configWeapons = await this.getConfigWeapons();
    const template_ids = [...configWeapons.map(i => i.template_id),]
    const atomicAssets = await firstValueFrom(this.getOwnedAssetsByTemplateIds(waxAddress, template_ids));
    const weapons: Weapon[] = [];
    atomicAssets.forEach(asset => {
      const weapon = configWeapons.find(b => b.template_id === asset.template.template_id.toString());
      if (weapon) {
        weapons.push(parseWeapon(asset, configWeapons));
      }
    });
    return weapons;
  }

  async getUserBlueprints(pfp_id: number, waxAddress: string): Promise<Blueprint[]> {
    const configBlueprints = await this.getConfigBlueprint();
    const configWeapons = await this.getConfigWeapons();
    const template_ids = [...configBlueprints.map(i => i.template_id),]
    const atomicAssets = await firstValueFrom(this.getOwnedAssetsByTemplateIds(waxAddress, template_ids));
    const blueprints: Blueprint[] = [];
    atomicAssets.forEach(asset => {
      const blueprint = configBlueprints.find(b => b.template_id === asset.template.template_id.toString());
      if (blueprint) {
        const weaponConfig = configWeapons.find(b => b.template_id === blueprint.result_template_id);
        blueprints.push({
          asset_id: asset.asset_id,
          template_id: blueprint.template_id,
          name: asset.name.split('|')[0].trim(),
          subtitle: weaponConfig.type,
          rarity: asset.immutable_data.rarity || asset.data.rarity,
          result_template_id: blueprint.result_template_id,
          cost: blueprint.cost / 100,
          resources: blueprint.resources,
        })
        return;
      }
    });
    return blueprints;
  }

  async getUserBoosters(pfp_id: number, waxAddress: string): Promise<Booster[]> {
    const configBooster = await this.getConfigBoosters();
    const template_ids = [...configBooster.map(i => i.template_id),]
    const atomicAssets = await firstValueFrom(this.getOwnedAssetsByTemplateIds(waxAddress, template_ids));
    const boosters: Booster[] = [];
    atomicAssets.forEach(asset => {
      const booster = configBooster.find(b => b.template_id.toString() === asset.template.template_id.toString());
      if (booster) {
        boosters.push({
          asset_id: asset.asset_id,
          template_id: booster.template_id.toString(),
          name: asset.name,
          rarity: asset.immutable_data.rarity || asset.data.rarity,
          bonus: booster.bonus,
        })
        return;
      }
    });
    return boosters;
  }

  async getUserSafebits(pfp_id: number, waxAddress: string): Promise<Safebit[]> {
    const configSafebit: { template_id: string | number }[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'confsafebit');
    const template_ids = configSafebit.map(i => i.template_id.toString());
    const atomicAssets = await firstValueFrom(this.getOwnedAssetsByTemplateIds(waxAddress, template_ids));
    const safebits: Safebit[] = [];
    atomicAssets.forEach(asset => {
      const safebit = configSafebit.find(b => b.template_id.toString() === asset.template.template_id.toString());
      if (safebit) {
        safebits.push({
          asset_id: asset.asset_id,
          template_id: safebit.template_id.toString(),
          name: asset.name,
        })
        return;
      }
    });
    return safebits;
  }

  //end inventory calls


  async getAssetsForDismantle(pfp_id: number, waxAddress: string): Promise<{weapon_list: Weapon[]}> {
    const configWeapons = await this.getConfigWeapons();
    const user = await this.getUser(waxAddress);
    const config_weapon_projects = await this.getConfigBlueprint();
    const atomicAssets = (await firstValueFrom(this.getOwnedAssetsByTemplateIds(waxAddress, configWeapons.map(c => c.template_id))))
      .filter(asset => //filtro tutte le weapon che hanno un progetto che li ha creati
        config_weapon_projects.some(config =>
          config.result_template_id === asset.template.template_id.toString()))
      .filter(asset => //filtro che scarta le armi equipaggiate
        asset.asset_id != user.equipped_ranged && asset.asset_id != user.equipped_melee
      )
    const weapons: Weapon[] = atomicAssets.map(asset => {
      const blueprint = config_weapon_projects.find(config => config.result_template_id === asset.template.template_id.toString());
      const weapon = parseWeapon(asset);
      weapon.outcome_resources = this.reduceResourceForDismantle(blueprint.resources, PERCENTAGE_DISMANTLE_RECEIVE, weapon.stats.level);
      return weapon;
    });
    return {
      weapon_list: weapons
    }
  }

  private reduceResourceForDismantle(obj: ResourceObj, percentageUserReceive: number, level: number): ResourceObj {
    return {
      water: Math.floor((obj.water + ((level -1) * obj.water * CRAFT_COST_PROGRESSION)) * percentageUserReceive),
      wood: Math.floor((obj.wood + ((level -1) * obj.wood * CRAFT_COST_PROGRESSION)) * percentageUserReceive),
      metal: Math.floor((obj.metal + ((level -1) * obj.metal * CRAFT_COST_PROGRESSION)) * percentageUserReceive)
    }
  }

  private getStatRange(template_id: string, level: number, configWeapons: ConfigWeapon[]) {
    const config = configWeapons.find(c => c.template_id === template_id);

    function getRange(level: number, stat: { value1: number, value0: number }, stat_prog: { value1: number, value0: number }): RangeStat {
      const damage_min = (level - 1) * stat_prog.value0 + stat.value0;
      const damage_max = (level - 1) * stat_prog.value1 + stat.value1;
      return {min: damage_min, max: damage_max};
    }

    return {
      damage: getRange(level, config.damage, config.damage_prog),
      rate: getRange(level, config.rate, config.rate_prog),
      range: getRange(level, config.range, config.range_prog),
      crit_chance: getRange(level, config.crit, config.crit_prog),
      crit_damage: getRange(level, config.crit_damage, config.crit_damage_prog),
    }
  }

  private async getWeaponConfig(config_weapons: ConfigWeapon[]): Promise<ConfigWeaponWithData[]> {
    return (await firstValueFrom((this.getTemplatesByIds(config_weapons.map(w => w.template_id))))).map(template =>{
      const config = config_weapons.find(c => c.template_id === template.template_id.toString());
      return {
        template_id: config.template_id.toString(),
        name: template.name,
        //imageUrl: getImageAsset(template) || (environment.imageGateway + 'QmUPtCqYk46fT1ssaHHXW43KDSaxDqMgsFGF6bbLcE2yzk'), //todo remove this fallback
        rarity: template.immutable_data.rarity || template.data?.rarity,
        ...config
      }
    })
  }

  private convertResourceToObj(resources: { first: number, second: string | number }[]): ResourceObj {
    function getValueFromArray(id_res: number, resources: { first: number, second: string | number }[]): number {
      return parseFloat((resources.find(r => r.first === id_res)?.second || '0').toString())
    }
    return {
      water: getValueFromArray(ResourceType.WATER, resources),
      wood: getValueFromArray(ResourceType.WOOD, resources),
      metal: getValueFromArray(ResourceType.METAL, resources),
    }
  }

  async getAirdropInfo(waxAddress: string): Promise<AirdropInfo> {
    const userInfo: UserInfo = await this.getUser(waxAddress);
    const resultAirdrop: {
      user: string,
      airdrop: { first: number, second: string | number }[]
    }[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'airdrops', 1, waxAddress, waxAddress);

    return {
      timestamp_id_res_0: userInfo?.airdrop_timestamps?.find(item => item.id_res === 0)?.timestamp || 0,
      timestamp_id_res_1: userInfo?.airdrop_timestamps?.find(item => item.id_res === 1)?.timestamp || 0,
      timestamp_id_res_2: userInfo?.airdrop_timestamps?.find(item => item.id_res === 2)?.timestamp || 0,
      resources: this.convertResourceToObj(resultAirdrop[0]?.airdrop ?? [])
    }
  }

  async getConfigWeapons(): Promise<ConfigWeapon[]> {
    const result: ConfWeaponRow[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'confweapons');
    return result.map(row => {
        return {
          template_id: row.template_id.toString(),
          type: row.type === 0 ? 'ranged' : 'melee',
          damage: this.convertTupleWax(row.damage),
          damage_prog: this.convertTupleWax(row.damage_prog),
          range: this.convertTupleWax(row.range),
          range_prog: this.convertTupleWax(row.range_prog),
          rate: this.convertTupleWax(row.rate),
          rate_prog: this.convertTupleWax(row.rate_prog),
          crit: this.convertTupleWax(row.crit),
          crit_prog: this.convertTupleWax(row.crit_prog),
          crit_damage: this.convertTupleWax(row.crit_damage),
          crit_damage_prog: this.convertTupleWax(row.crit_damage_prog)
        }
      }
    );
  }

  async getConfigBoosters(): Promise<{template_id: string, bonus: number }[]> {
    const result: ConfBoosterRow[] = await this.getTable(this.contractPolyverse(), this.contractPolyverse(), 'confbooster');
    return result.map(row => {
      return {
        template_id: row.template_id.toString(),
        bonus: parseFloat(row.value.toString())
      }
    })
  }

  private convertTupleWax(tuple: TupleWaxTable<string | number>): { value1: number, value0: number } {
    return {
      value0: parseFloat(tuple.field_0.toString()),
      value1: parseFloat(tuple.field_1.toString())
    }
  }

  async getTable(contract: string, scope: string | null, table: string, limit = 1000, lower_bound?: string | number | null, upper_bound?: string | number | null, index_position?: number | null, key_type?: string | null, reverse: boolean = false): Promise<any> {
    try {
      return await this.getTableThrowError(contract, scope, table, limit, lower_bound, upper_bound, index_position, key_type, reverse)
    } catch (e) {
      this._log.log('tableRows: ' + table + ' - ' + scope);
      let errorText = ''
      if (!environment.production) {
        errorText = ' (table: ' + table + ' - contract: ' + contract + ')';
      }
      this._toastService.open(e.message + errorText, ToastType.ERROR);
      return [];
    }
  }

  async getTableThrowError(contract: string, scope: string | null, table: string, limit = 1000, lower_bound?: string | number | null, upper_bound?: string | number | null, index_position?: number | null, key_type?: string | null, reverse: boolean = false): Promise<any> {
    const res = (await firstValueFrom(this.post("/v1/chain/get_table_rows",
      {
        key_type: key_type,
        lower_bound: lower_bound,
        upper_bound: upper_bound,
        json: true,
        code: contract,
        scope,
        table,
        limit,
        reverse: reverse,
        show_payer: false,
        index_position
      },
    ))).rows;
    this._log.log('tableRows: ' + table + ' - ' + scope);
    this._log.log(res);
    return res;
  }

}
