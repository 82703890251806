<div class="step-section" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px"
     [ngClass]="{'enabled': currentStep >= position, 'no-progress' : !showProgress}">
  <div class="circle" fxLayoutAlign="center center">
    <span>{{position + 1}}</span>
    <mat-spinner class="spinner" color="accent" diameter="30" *ngIf="(position - 1) === currentStep && canShowRefresh"></mat-spinner>
    <div class="container-labels" fxLayout="column" fxLayoutAlign="start center">
      <span class="label">{{label}}</span>
      <span *ngIf="subtitle">{{subtitle}}</span>
    </div>
  </div>
  <div class="progress-container" *ngIf="showProgress">
    <div class="progress" [ngStyle]="{'width' : percentageProgress + '%'}"></div>
  </div>
</div>
