<div fxLayout="column">
  <div class="modalToolbar" fxLayoutAlign="space-between center">
    <span>{{data.title}}</span>
    <div mat-dialog-close>
      <img src="/assets/images/ic-close.png" height="40" class="close-button" draggable="false">
    </div>
  </div>

  <div fxLayout="column" class="modalContent">

    <div fxLayout="row wrap" style="gap: 20px">
      <div fxLayout="column" style="flex: 1 1 276px" fxLayoutAlign="start center">
        <div class="character-icon-zone">
          <div class="level-tag" fxLayout="column" fxLayoutAlign="start center">
            <span class="label">LEVEL</span>
            <span class="value" *ngIf="level">{{level}}</span>
          </div>
          <img [src]="data.imageUrl" class="full-size" style="object-fit: cover" draggable="false">
        </div>
        <div fxLayout="row" fxFlexOffset="20px" class="full-width">
          <div class="button-stake button flex-50" fxLayoutAlign="center center" fxLayoutGap="2px"
               *ngIf="data.status === STATUS_ASSET.PENDING; else normalState" (click)="continuePending()">
            <img src="/assets/images/icon-park_export.png" height="16" draggable="false">
            <span>CONTINUE {{data.pending_type === PADDING_TYPE.STAKE ? 'STAKE' : 'UNSTAKE'}}</span>
          </div>
          <ng-template #normalState>
            <div class="button-stake button flex-50" fxLayoutAlign="center center" fxLayoutGap="2px"
                 *ngIf="data.status !== STATUS_ASSET.IN_GAME; else exportButton" (click)="doImport()">
              <img src="/assets/images/icon-park_export.png" height="16" draggable="false">
              <span>STAKE</span>
            </div>
            <ng-template #exportButton>
              <div class="button-import button flex-50" fxLayoutAlign="center center" fxLayoutGap="2px" (click)="doExport()">
                <img src="/assets/images/icon-park_export.png" height="16" draggable="false">
                <span>UNSTAKE</span>
              </div>
            </ng-template>
            <div class="button-play button flex-50" fxLayoutAlign="center center"
                 *ngIf="data.status === STATUS_ASSET.IN_GAME"
                 (click)="goToPlay()">
              <mat-spinner color="accent" diameter="30" *ngIf="isLoadingPlay; else textPlay"></mat-spinner>
              <ng-template #textPlay>
                PLAY
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="4px" style="flex: 999999 1 250px">
        <div class="info-header" fxLayout="row" fxLayoutAlign="start center">
          <span class="asset-name">{{data.race}}</span>
          <span class="asset-mint">#{{data.mint}}</span>
          <mat-spinner color="accent" diameter="20" *ngIf="isLoading"></mat-spinner>
        </div>
        <div class="stats-zone" fxLayout="column">
          <div fxLayout="row" class="stats-info" fxLayoutAlign="start center">
            <img src="/assets/images/ic-round-exp.png" height="40" width="40" draggable="false">
            <div fxLayout="column">
              <div fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                  <span style="color: #A7A5C6">Exp: </span>
                  <strong>{{exp}}</strong>
                </div>
                <strong>{{next_exp}}</strong>
              </div>
              <div class="progress-bar">
                <div class="progress" *ngIf="exp" [ngStyle]="{'width': ((exp/next_exp) * 100) + '%'}"></div>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" class="full-width" style="gap: 16px" fxFlexOffset="16px">
            <div class="column-stats" fxLayout="column">
              <span class="card-title">Bonus Stats</span>
              <div class="radius-card" fxLayout="column" fxLayoutAlign="stretch start" fxLayoutGap="10px">
                <div fxLayout="row" *ngFor="let row of listStats" fxLayoutAlign="stretch center">
                  <img [src]="row.imageUrl" width="24" height="24" />
                  <span fxFlex class="stat-name" fxFlexOffset="6px">{{row.title}}</span>
                  <span *ngIf="row.value === 0; else valuesView" style="color: #A7A5C6">--</span>
                  <ng-template #valuesView>
                    <span class="value-stat" [ngClass]="{'negative': row.value < 0}">{{row.value}}%</span>
                    <img [src]="'/assets/icons/' + (row.value >= 0 ? 'ic-arrow-up.svg' : 'ic-arrow-down.svg')" width="14" height="14" fxFlexOffset="4px"/>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="column-stats" fxLayout="column">
              <span class="card-title">Bonus Rewards</span>
              <div class="radius-card" fxLayout="column" fxLayoutAlign="stretch start" fxLayoutGap="10px">
                <div fxLayout="row" *ngFor="let row of listRewards" fxLayoutAlign="stretch center">
                  <img [src]="row.imageUrl" width="24" height="24" />
                  <span fxFlex class="stat-name" fxFlexOffset="6px">{{row.title}}</span>
                  <span *ngIf="row.value === 0; else valuesView" style="color: #A7A5C6">--</span>
                  <ng-template #valuesView>
                    <span class="value-stat" [ngClass]="{'negative': row.value < 0}">{{row.value}}%</span>
                    <img [src]="'/assets/icons/' + (row.value >= 0 ? 'ic-arrow-up.svg' : 'ic-arrow-down.svg')" width="14" height="14" fxFlexOffset="4px"/>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" class="assets-zone" fxFlexOffset="20px">
      <div class="asset-header" fxLayout="row" fxLayoutAlign="start center">
        <span class="asset-label">Assets</span>
        <span class="asset-count">{{list.length}}</span>
        <span fxFlex></span>
        <img src="/assets/icons/ic-import.svg" width="20" height="20"/>
        <span class="modal-link" (click)="openImport()">IMPORT ASSETS</span>
      </div>
      <div class="assets-content">
        <div fxLayoutAlign="center center" fxLayout="column" class="no-assets">
          <img src="/assets/images/no-assets.png" width="200" draggable="false">
          <span class="warn">You don't own any assets!</span>
          <span class="link">BUY ASSETS</span>
        </div>
      </div>
    </div>
  </div>


</div>
