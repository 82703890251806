import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CharacterImportModalComponent} from "../character-import-modal/character-import-modal.component";
import {ETHService} from "../../../../core/services/connector.service";
import {StakeSignModalComponent} from "../../../../shared/components/stake-sign-modal/stake-sign-modal.component";
import {firstValueFrom} from "rxjs";
import {UnstakeSignModalComponent} from "../../../../shared/components/unstake-sign-modal/unstake-sign-modal.component";
import {Router} from "@angular/router";
import {Character, PADDING_TYPE, STATUS_ASSET} from "../../../../core/models/ethResponse";
import {ToastService, ToastType} from "../../../../core/services/toast.service";
import {WaxChainService} from "../../../../core/services/wax-chain.service";
import {UnityData} from "../../../../core/models/waxAccountInfo";
import {getBonusReward, getBonusStats, ResourceConfigItem, StatConfigItem} from "../../../../core/utils/functions";
import {LogService} from "../../../../core/services/log.service";

@Component({
  selector: 'fts-character-info-modal',
  templateUrl: './character-info-modal.component.html',
  styleUrls: ['./character-info-modal.component.scss']
})
export class CharacterInfoModalComponent implements OnInit{

  isLoadingPlay: boolean = false;
  list = [];
  isLoading = false;

  listStats: {imageUrl: string, title: string, value: number}[] = [];
  listRewards: {imageUrl: string, title: string, value: number}[] = [];

  level: number | undefined;
  exp: number = 0;
  next_exp: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Character,
    protected _dialogRef: MatDialogRef<CharacterInfoModalComponent>,
    private _dialog: MatDialog,
    private _eth: ETHService,
    private _logger: LogService,
    private _router: Router,
    private _toastService: ToastService,
    private _waxChainService: WaxChainService,
  ) {
    _dialogRef.addPanelClass('large-modal')

    const race = data.race.toLowerCase().trim();
    this.listStats = this.convertStatsInList(getBonusStats()[race]).sort((a, b) => {
      return this.sortFunctionStat(b.value) - this.sortFunctionStat(a.value)
    })
    this.listRewards = this.convertResourcesInList(getBonusReward()[race]).sort((a, b) => {
      return this.sortFunctionStat(b.value) - this.sortFunctionStat(a.value)
    })
  }

  ngOnInit() {
    this.refresh();
  }

  async refresh() {
    this.isLoading = true;
    try {
      const auth = await this._waxChainService.getAuthUser(this.data.mint);
      const userInfo = await this._waxChainService.getUser(auth.wax_address);
      this.level = userInfo.level;
      this.exp = userInfo.exp;
      this.next_exp = WaxChainService.calculateNextExperience(userInfo.level, userInfo.exp);
    } catch (e) {
      this._logger.error(e);
      this._toastService.open('Error retrieving user info: ' + (e?.errorMessage ?? e?.errorDetail ?? e.message), ToastType.ERROR);
    } finally {
      this.isLoading = false;
    }

  }

  openImport(): void {
    this._dialog.open(CharacterImportModalComponent);
  }

  async continuePending() {
    switch (this.data.pending_type) {
      case PADDING_TYPE.STAKE:
        this.doImport();
        break;
      case PADDING_TYPE.UNSTAKE:
        this.doExport();
        break;
      default:
        this._toastService.open('Error status data. Refresh', ToastType.ERROR);
        break;
    }
  }

  async doImport() {
    const result = await firstValueFrom(this._dialog.open(StakeSignModalComponent, {data: this.data}).afterClosed())
    if (result) {
      this._dialogRef.close(true);
    }
  }

  async doExport() {
    const result = await firstValueFrom(this._dialog.open(UnstakeSignModalComponent, {data: this.data}).afterClosed())
    if (result) {
      this._dialogRef.close(true);
    }
  }

  async goToPlay() {
    if (this.isLoadingPlay) {
      return;
    }
    this.isLoadingPlay = true;
    const auth = await this._waxChainService.getAuthUser(this.data.mint);
    if (auth) {
      const unityData: UnityData = {pfp_id: this.data.mint, waxAddress: auth.wax_address}
      await this._router.navigate(["/game"], { state: unityData });
      this.isLoadingPlay = false;
    }
    this._dialogRef.close();
  }

  readonly STATUS_ASSET = STATUS_ASSET;
  readonly PADDING_TYPE = PADDING_TYPE;

  private sortFunctionStat(value: number) {
    if (value === 0) return Number.MIN_SAFE_INTEGER;
    else return value;
  }

  private convertStatsInList(item: StatConfigItem) {
    const baseUrl = '/assets/icons/stats/';
    return [
      {
        imageUrl: baseUrl + 'ic-stat-gun-damage.png',
        title: 'Gun damage',
        value: item.gun_damage
      },
      {
        imageUrl: baseUrl + 'ic-stat-hp.png',
        title: 'Hp',
        value: item.hp
      },
      {
        imageUrl: baseUrl + 'ic-stat-critical.png',
        title: 'Critical',
        value: item.crit_damage
      },
      {
        imageUrl: baseUrl + 'ic-stat-mv-speed.png',
        title: 'Movement Speed',
        value: item.speed
      },
      {
        imageUrl: baseUrl + 'ic-stat-rifle-damage.png',
        title: 'Rifle Damage',
        value: item.rifle_damage
      },
      {
        imageUrl: baseUrl + 'ic-stat-melee-damage.png',
        title: 'Melee Damage',
        value: item.melee_damage
      },
      {
        imageUrl: baseUrl + 'ic-ranged-damage.png',
        title: 'Ranged Damage',
        value: item.range_damage
      },
    ]
  }

  private convertResourcesInList(item: ResourceConfigItem) {
    const baseUrl = '/assets/icons/stats/';
    return [
      {
        imageUrl: baseUrl + 'ic-res-wood.png',
        title: 'Wood',
        value: item.wood
      },
      {
        imageUrl: baseUrl + 'ic-res-metal.png',
        title: 'Metal',
        value: item.metal
      },
      {
        imageUrl: baseUrl + 'ic-res-water.png',
        title: 'Water',
        value: item.water
      }
    ]
  }
}
