import {HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError, from} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {FireService} from "../services/fire.service";

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class FirebaseAuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private _fireService: FireService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // @ts-ignore
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this._fireService.getJwtToken(true)).pipe(
        switchMap((jwt: string) => {
          this.isRefreshing = false;
          this._fireService.saveJWT(jwt)
          this.refreshTokenSubject.next(jwt);
          return next.handle(this.addTokenHeader(request, jwt));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          // this.tokenService.signOut();
          return throwError(err);
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)});
  }
}

export const authInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: FirebaseAuthInterceptor, multi: true}
];
