import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AssetModalComponent} from "./modals/asset-modal/asset-modal.component";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'fts-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {


  sortControl = new FormControl('0');

  constructor(
    private _modal: MatDialog
  ) { }

  ngOnInit() {
  }

  openAsset(): void {
    this._modal.open(AssetModalComponent);
  }

}
