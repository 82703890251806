<div fxLayout="column">
  <div class="modalToolbar" fxLayoutAlign="space-between center">
    <span>Asset Detail</span>
    <div mat-dialog-close>
      <img src="/assets/images/ic-close.png" height="40" class="close-button" draggable="false">
    </div>
  </div>

  <div fxLayout="column" class="modalContent">

    <div fxLayout="row">
      <div fxLayout="column">
        <div class="character-icon-zone">
          <div class="level-tag">Level 1</div>
          <img src="/assets/characters/human.png" width="276" height="276" draggable="false">
        </div>

        <div class="button-import button" fxFlexOffset="20px" fxLayoutAlign="center center" fxLayoutGap="2px">
          <img src="/assets/images/icon-park_export.png" height="16" draggable="false">
          <span>IMPORT</span>
        </div>
      </div>

      <div fxLayout="column" fxFlexOffset="20px" fxLayoutGap="4px" fxFlex>
        <div class="info-header" fxLayout="row" fxLayoutAlign="start center">
          <span class="asset-name">Hermes</span>
          <span class="asset-mint">#42</span>
        </div>
        <div class="stats-zone" fxLayout="column" fxLayoutGap="32px">

          <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="start center">

            <div class="category-row" fxLayoutAlign="space-between center">
              <img src="/assets/images/category.png" height="40" class="cat-icon">
              <span>CATEGORY</span>
              <span>GUN RANGED</span>
            </div>

            <div class="category-row" fxLayoutAlign="space-between center">
              <img src="/assets/images/rarity.png" height="40" class="cat-icon">
              <span>RARIRTY</span>
              <span>COMMON</span>
            </div>

          </div>

          <div fxLayout="row" class="stats-info" fxLayoutAlign="start center">
            <img src="/assets/images/heart-icon.png" height="40" width="40" draggable="false">
            <div fxLayout="column">
              <div fxLayoutAlign="space-between center">
                <span>Damage</span>
                <strong>100</strong>
              </div>
              <div class="progress-bar">
                <div class="progress" style="width: 50%"></div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">

            <div fxLayout="row" class="stats-info" fxLayoutAlign="start center">
              <img src="/assets/images/speed-icon.png" height="40" width="40" draggable="false">
              <div fxLayout="column">
                <div fxLayoutAlign="space-between center">
                  <span>Range</span>
                  <strong>100</strong>
                </div>
                <div class="progress-bar">
                  <div class="progress"></div>
                </div>
              </div>
            </div>

            <div fxLayout="row" class="stats-info" fxLayoutAlign="start center">
              <img src="/assets/images/ranged-icon.png" height="40" width="40" draggable="false">
              <div fxLayout="column">
                <div fxLayoutAlign="space-between center">
                  <span>ATK Speed</span>
                  <strong>100</strong>
                </div>
                <div class="progress-bar">
                  <div class="progress"></div>
                </div>
              </div>
            </div>

          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">

            <div fxLayout="row" class="stats-info" fxLayoutAlign="start center">
              <img src="/assets/images/speed-icon.png" height="40" width="40" draggable="false">
              <div fxLayout="column">
                <div fxLayoutAlign="space-between center">
                  <span>Critical %</span>
                  <strong>100</strong>
                </div>
                <div class="progress-bar">
                  <div class="progress"></div>
                </div>
              </div>
            </div>

            <div fxLayout="row" class="stats-info" fxLayoutAlign="start center">
              <img src="/assets/images/ranged-icon.png" height="40" width="40" draggable="false">
              <div fxLayout="column">
                <div fxLayoutAlign="space-between center">
                  <span>Headshot</span>
                  <strong>100</strong>
                </div>
                <div class="progress-bar">
                  <div class="progress"></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" class="assets-zone" fxFlexOffset="20px">
      <div class="asset-header" fxLayout="row" fxLayoutAlign="start center">
        <span class="asset-label">Character associated</span>
      </div>
      <div class="assets-content">
        <div fxLayoutAlign="center center" fxLayout="column" class="no-assets">
          <img src="/assets/images/no-assets.png" width="200" draggable="false">
          <span class="warn">There is no character associated here</span>
          <span class="modal-link">IMPORT</span>
        </div>
      </div>
    </div>
  </div>

</div>

