<div class="container full-width" fxLayout="column" fxLayoutAlign="center center">
  <span class="title">{{titleModal}}</span>
  <span class="subtitle" fxFlexOffset="10px" *ngIf="description">{{description}}</span>
  <div class="container-steps" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center" fxFlexOffset="80px">
    <fts-step-circle
      [position]="0"
      [currentStep]="currentStep"
      [label]="'Transaction Signed'"
      [subtitle]="transactionsSigned + '/' + transactionsCount"
      [percentageProgress]="percentageTransaction"
    ></fts-step-circle>
    <fts-step-circle
      [position]="1"
      [currentStep]="currentStep"
      [label]="'Transaction Confirmed'"
      [subtitle]="transactionsConfirmed + '/' + transactionsCount"
      [percentageProgress]="0"
      [showProgress]="false"
    ></fts-step-circle>
  </div>
</div>
