<div class="spinner-layer" fxLayoutAlign="center center" *ngIf="isLoading">
  <mat-spinner color="accent"></mat-spinner>
</div>
<div id="unity-container" class="unity-desktop">
  <div id="canvas-container" fxLayoutAlign="center center" fxLayout="column">
    <canvas id="unity-canvas"></canvas>
  </div>
  <div id="unity-loading-bar">
    <div id="unity-logo"></div>
    <div id="unity-progress-bar-empty">
      <div id="unity-progress-bar-full"></div>
    </div>
  </div>
  <div class="container-loading" fxLayout="column" fxLayoutAlign="center center" *ngIf="showLoading" [@leaveOpacityAnimation]>
    <div class="full-size" fxLayout="column" fxLayoutAlign="center center">
      <img src="/assets/images/logo-polyverse-intro.png" alt="logo" class="loading-image">
    </div>
    <div class="container-loading-views" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="14px">
      <span class="loading-text" fxFlexOffset="16px">Loading</span>
      <div class="loading-unity">
        <div class="loading-bar" [style.width]="widthProgress"></div>
      </div>
    </div>
  </div>
</div>
