<div id="toolbar" fxLayoutAlign="space-between center">
  <img class="polyverse-label" src="/assets/images/polyverse-label.png"/>

  <div fxLayout="row" class="navigation-zone" fxHide.lt-md>
    <a style="color: white; text-decoration: none" *ngFor="let item of links" routerLinkActive="active" [routerLink]="item.link" [routerLinkActiveOptions]="{exact: true}">
      <div class="menu-link" fxLayoutAlign="center center">
        {{item.title}}
      </div>
    </a>
  </div>
  <div fxHide.lt-md>
    <fts-login-button
      [addressETH]="addressETH$ | async"
      (onMouseEnter)="openMenu()"
      (onClickAddress)="toggleMenu()"
      (onLogin)="login()">
    </fts-login-button>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px" class="menu pointer"
         *ngIf="showMenu" (mouseleave)="closeMenu()"
         (click)="logout()">
      <img src="assets/icons/ic-logout.svg" width="24" height="24"/>
      <span class="logout-text">LOGOUT</span>
    </div>
  </div>
  <div class="menu-mobile" fxLayout="column" fxLayoutAlign="start center" *ngIf="showMobileMenu" fxHide.gt-sm>
    <div class="row-item" fxLayout="row" fxLayoutAlign="center center">
      <fts-login-button
        [addressETH]="addressETH$ | async"
        [showArrow]="false"
        (onLogin)="login()">
      </fts-login-button>
    </div>
    <div class="row-item can-be-active pointer" *ngFor="let item of links"  fxLayout="row" fxLayoutAlign="center center">
      <a style="color: white; text-decoration: none" routerLinkActive="active" [routerLink]="item.link" (click)="showMobileMenu = false" [routerLinkActiveOptions]="{exact: true}">
        <span class="router-link">{{item.title}}</span>
      </a>
    </div>
    <div class="row-item pointer can-be-active" *ngIf="addressETH$ | async" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px" (click)="logout()">
      <img src="assets/icons/ic-logout.svg" width="24" height="24"/>
      <span class="logout-text">LOGOUT</span>
    </div>
  </div>
  <img src="/assets/icons/ic-settings-mobile.png" class="pointer" width="38" height="38" draggable="false" fxHide.gt-sm (click)="showMobileMenu = !showMobileMenu"/>
</div>
