import { Component } from '@angular/core';

@Component({
  selector: 'fts-asset-modal',
  templateUrl: './asset-modal.component.html',
  styleUrls: ['./asset-modal.component.css']
})
export class AssetModalComponent {

}
