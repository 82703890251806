import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastaService, ToastaConfig, ToastOptions} from 'ngx-toasta';

@Injectable({providedIn: 'root'})
export class ToastService {

  constructor(
    private toastaService:ToastaService,
    private toastaConfig: ToastaConfig,
    private _snack: MatSnackBar,
  ) {
    this.toastaConfig.theme = 'default';
    this.toastaConfig.position = 'top-right'
  }

  open(message: string, toastType: ToastType = ToastType.SUCCESS): any | null {
    const toastOptions:ToastOptions = {
      msg: message,
      showClose: true,
      timeout: 7000,
      theme: 'default',
    };

    switch (toastType) {
      case ToastType.SUCCESS: this.toastaService.success(toastOptions); break;
      case ToastType.ERROR: this.toastaService.error(toastOptions); break;
      default: this.toastaService.info(toastOptions); break;
    }

    return null;
  }

}

export enum ToastType {
  SUCCESS,
  INFO,
  WAITING,
  ERROR,
}
