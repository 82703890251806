import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BaseService} from "./base.service";
import {SessionStore} from "../stores/session/session.store";
import {SessionQuery} from "../stores/session/session.query";
import {LogService} from "./log.service";
import {environment} from "../../../environments/environment";
import {GameConfigQuery} from "../stores/gameconfig/gameconfig.query";
import {FireService} from "./fire.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ETHService} from "./connector.service";
import {firstValueFrom} from "rxjs";

@Injectable({providedIn: 'root'})
export class BackendService extends BaseService {

  private authHeaders(): any {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + this._fireService.getCurrentJWT());
    this._log.log(headers);
    return headers;
  }

  constructor(
    protected _http: HttpClient,
    protected _log: LogService,
    protected _snack: MatSnackBar,
    protected _session: SessionQuery,
    private _gameConfig: GameConfigQuery,
    private _sessionStore: SessionStore,
    private _fireService: FireService,
    private _fireStore: AngularFirestore,
    private _eth: ETHService,
  ) {
    super(_http, _session, _log, _snack);
    this.basePath = environment.firebaseUrl;
  }

  async refreshJWTFirebase(): Promise<void> {
    await this._fireService.refreshJWTFirebase();
  }

  async stake(pfp_id: string, trx_id: string, callbackSigned: (() => void) | undefined = undefined): Promise<{
    success: boolean,
    errorMessage: string | null,
    data: any | undefined | null
  }> {
    return await this.postBackend(
      '/polyverse-game/stake',
      {pfp_id: pfp_id, trx_id: trx_id},
      true,
      callbackSigned
    );
  }

  async unstake(pfp_id: string, trx_id: string, callbackSigned: (() => void) | undefined = undefined): Promise<{
    success: boolean,
    errorMessage: string | null,
    data: any | undefined | null
  }> {
    return await this.postBackend(
      '/polyverse-game/unstake',
      {pfp_id: pfp_id, trx_id: trx_id},
      true,
      callbackSigned
    );
  }

  async getProof(id: number): Promise<{
    success: boolean,
    errorMessage: string | null,
    data: any | undefined | null
  }> {
    const address = this._session.addressETH;
    const result = await this.postBackend('/polyverse-user/getProof',
      {
        address,
        id,
      },
      false,
    )
    if (result.success && result.data) {
      result.data.whitelist_id = id;
    }
    return result;
  }

  //actions unity:

  async buyShop(pfp_id: number, shop_id: number | string) {
    return await this.postBackendOrThrow(
      '/polyverse-game/buy',
      {pfp_id: pfp_id, shop_id: parseInt(shop_id.toString(), 10)},
      true,
    );
  }

  craftWeapon(pfp_id: number, blueprint_asset_id: string, blueprint_template_id: string, booster_asset_id: string) {
    return this.postBackendOrThrow('/polyverse-game/craft',
      {
        pfp_id,
        blueprint_asset_id,
        blueprint_template_id,
        booster_asset_id,
      },
      true,
    )
  }

  reroll(pfp_id: number, weapon_asset_id: string, stat_name: string, shield_asset_id: string | undefined | null) {
    return this.postBackendOrThrow('/polyverse-game/reroll',
      {
        pfp_id,
        weapon_asset_id: weapon_asset_id,
        stat_name: stat_name,
        safebit_asset_id: shield_asset_id || null,
      },
      true,
    )
  }

  dismantle(pfp_id: number, weapon_asset_id: string) {
    return this.postBackendOrThrow('/polyverse-game/dismantle',
      {
        pfp_id,
        weapon_asset_id: weapon_asset_id,
      },
      true,
    )
  }

  setEquip(pfp_id: number, data: any) {
    return this.postBackendOrThrow('/polyverse-game/equip',
      {
        pfp_id,
        ranged_asset_id: data.ranged_asset_id,
        melee_asset_id: data.melee_asset_id,
      },
      true,
    )
  }

  async completeTutorial(pfp_id: number, wax_address: string) {
    const result = this.postBackendOrThrow('/polyverse-user/tutorial',
      {
        pfp_id,
        wax_address: wax_address
      },
      false,
    )
    await new Promise(resolve => setTimeout(resolve, 3000));
    return result
  }

  async resetTutorial(pfp_id: number) {
    const result = await this.postBackendOrThrow('/polyverse-user/resetTutorial',
      {
        pfp_id,
        address: this._session.addressETH
      },
      false,
    )
    this._snack.open('Tutorial reset successfully!');
    return result;
  }

  async requestAirdrop(pfp_id: number, resource_id: number | string) {
    const result =  await this.postBackendOrThrow(
      '/polyverse-game/airdrop',
      {pfp_id: pfp_id, resource_id: parseInt(resource_id.toString(), 10)},
      true,
    );
    await new Promise(resolve => setTimeout(resolve, 3000));
    return result
  }

  async claimResource(pfp_id: number) {
    return await this.postBackendOrThrow(
      '/polyverse-game/claimResource',
      {pfp_id: pfp_id},
      true,
    );
  }

  async getStatus(pfp_id: number, wax_address: string) {
    return await this.postBackendOrThrow('/polyverse-user/status',
      {
        pfp_id,
        address: this._session.addressETH,
        wax_address: wax_address
      },
      false,
    );
  }

  async startGame(pfp_id: number, data: any) {
    return await this.postBackendOrThrow(
      '/polyverse-game/start',
      {
        pfp_id: pfp_id,
        region: this._gameConfig.regionNoSpace
      },
      true,
    );
  }

  async postBackendOrThrow(path: string, body: any, needAuth: boolean, callbackSigned: (() => void) | undefined = undefined): Promise<any> {
    const result = await this.postBackend(path, body, needAuth, callbackSigned);
    if (result.success) {
      return result.data;
    }
    throw Error(result.errorMessage);
  }

  async postBackend(path: string, body: any, needAuth: boolean, callbackSigned: (() => void) | undefined = undefined): Promise<{
    success: boolean,
    errorMessage: string | null,
    data: any | undefined | null
  }> {
    try {
      let signature: any = {};
      if (needAuth) {
        signature = await this._eth.signatureBody();
        if (callbackSigned) {
          callbackSigned();
        }
      }
      await this.refreshJWTFirebase();
      if (!this._fireService.getCurrentJWT()) {
        throw Error('Error authentication. Refresh');
      }
      const result = await firstValueFrom(this.post(path,
        {
          ...signature,
          ...body,
        },
        this.authHeaders()
      ))
      return {success: true, errorMessage: null, data: result};
    } catch (e) {
      const errorMessage: string = e?.details ?? e?.message ?? e?.error ?? 'Error';
      return {success: false, errorMessage: errorMessage, data: null};
    }
  }

}
