<div class="wallet-zone" *ngIf="addressETH; else loginView" fxLayout="row" (mouseenter)="onMouseEnter.emit()"
     (click)="onClickAddress.emit()"
     fxLayoutAlign="start center" fxLayoutGap="2px">
  <img src="/assets/images/ic-character.png" height="24" width="24">
  <span>{{addressETH | obscureAddress}}</span>
  <img src="/assets/icons/ic-arrow-bottom.svg" height="12" width="12" fxFlexOffset="4px" *ngIf="showArrow"/>
</div>
<ng-template #loginView>
  <button mat-button class="main-button button-login" fxFlexOffset="4px" (click)="onLogin.emit()">CONNECT WALLET</button>
</ng-template>
