
<ngx-toasta [position]="'top-right'"></ngx-toasta>
<div fxLayout="column" fxLayoutAlign="start stretch">
  <router-outlet></router-outlet>
  <fts-footer *ngIf="showFooter"></fts-footer>
</div>


<!--
<ngx-toasta [position]="'top-right'"></ngx-toasta>
<div class="router-container">
  <router-outlet></router-outlet>
</div>
<fts-footer></fts-footer>-->
