import {Injectable} from "@angular/core";
import {SessionStore} from "../stores/session/session.store";
import {LogService} from "./log.service";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SessionQuery} from '../stores/session/session.query';
import {FireService} from "./fire.service";
import {BackendService} from "./backend.service";
import {SessionInfo} from "../models/waxAccountInfo";

@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(
    private _http: HttpClient,
    private _log: LogService,
    private _snack: MatSnackBar,
    private _session: SessionQuery,
    private _sessionStore: SessionStore,
    private _backendService: BackendService,
    private _fireService: FireService,
  ) {
  }

  async initFirebase(): Promise<void> {
    const firebaseUser = await this._fireService.getCurrentUser();
    if (!firebaseUser) {
      this._sessionStore.clearAccount();
    }
  }

  async login(email: string, password: string): Promise<SessionInfo> {
    await this._fireService.signIn(email, password);
    await this._backendService.refreshJWTFirebase();
    const data: SessionInfo = {account: email};
    this._sessionStore.update({addressWax: email, mail: email});
    return data;
  }

  async register(email: string, password: string): Promise<SessionInfo> {
    await this._fireService.signUp(email, password);
    await this._backendService.refreshJWTFirebase();
    const data: SessionInfo = {account: email};
    this._sessionStore.update({addressWax: email, mail: email});
    return data;
  }

  async resetPassword(email: string): Promise<void> {
    await this._fireService.passwordResetEmail(email);
  }

  async openPopUp(): Promise<void> {
    const popup = document.getElementById('popup-window');
    popup!.setAttribute('style', 'display: block');
    popup!.setAttribute('class', 'popup-window-visible');
  }

  closePopUp() {
    const popup = document.getElementById('popup-window');
    popup!.removeAttribute('class');
  }

  async logout(): Promise<void> {
    await this._fireService.logout();
    this._sessionStore.clearAccount();
  }

}
