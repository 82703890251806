import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {environment} from "../environments/environment";
import { UnityComponent } from './pages/unity/unity.component';
import {TacoLoginWaxComponent} from "./shared/components/taco-login-wax/container/taco-login-wax.component";
import {authInterceptorProviders} from "./core/interceptors/FirebaseAuthInterceptor";
import {firebaseConfig} from "../firebase/firebase";
import { ServiceWorkerModule } from '@angular/service-worker';
import {MobilePwaPopupComponent} from "./shared/components/mobile-pwa-popup/mobile-pwa-popup.component";
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { CharactersComponent } from './pages/characters/characters.component';
import { AssetsComponent } from './pages/assets/assets.component';
import { MarketplaceComponent } from './pages/marketplace/marketplace.component';
import { CharacterCardComponent } from './pages/characters/components/character-card/character-card.component';
import { AssetCardComponent } from './pages/assets/components/asset-card/asset-card.component';
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireRemoteConfigModule, SETTINGS} from "@angular/fire/compat/remote-config";
import {AngularFireAnalyticsModule} from "@angular/fire/compat/analytics";
import { CharacterInfoModalComponent } from './pages/characters/modals/character-info-modal/character-info-modal.component';
import { CharacterImportModalComponent } from './pages/characters/modals/character-import-modal/character-import-modal.component';
import { AssetModalComponent } from './pages/assets/modals/asset-modal/asset-modal.component';
import {SignModalComponent} from "./shared/components/sign-modal/sign-modal.component";
import {StepCircleComponent} from "./shared/components/sign-modal/step-circle/step-circle.component";
import {StakeSignModalComponent} from "./shared/components/stake-sign-modal/stake-sign-modal.component";
import {UnstakeSignModalComponent} from "./shared/components/unstake-sign-modal/unstake-sign-modal.component";
import {SharedModule} from "./shared/modules/shared.module";
import {ToastaModule} from "ngx-toasta";
import {AppGuard} from "./core/guards/app.guard";
import {SimpleSignModalComponent} from "./shared/components/simple-sign-modal/simple-sign-modal.component";

@NgModule({
  declarations: [
    AppComponent,
    UnityComponent,
    TacoLoginWaxComponent,
    SignModalComponent,
    SimpleSignModalComponent,
    StakeSignModalComponent,
    UnstakeSignModalComponent,
    StepCircleComponent,
    MobilePwaPopupComponent,
    LoginComponent,
    HomeComponent,
    CharactersComponent,
    AssetsComponent,
    MarketplaceComponent,
    CharacterCardComponent,
    AssetCardComponent,
    CharacterInfoModalComponent,
    CharacterImportModalComponent,
    AssetModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastaModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    AppGuard,
    {provide: SETTINGS, useFactory: () => ({minimumFetchIntervalMillis: 60_000, fetchTimeoutMillis: 60_000})},
    authInterceptorProviders,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
