import { Injectable } from '@angular/core';
import {LocalStorageService} from "./local-storage.service";
import * as Moment from "moment";
import {LogService} from "../../core/services/log.service";

@Injectable({
  providedIn: 'root',
})
export class CacheResponseService {

  private readonly TIME_CACHE_VALID = 600;
  private readonly PREFIX_KEY = 'cached-resp-';
  readonly PREFIX_STAKED_KEY = 'staked-';
  constructor(
    private _localStorageService: LocalStorageService,
    private _logger: LogService,
  ) {

  }

  getData<T>(key: string, cacheForSc: number = this.TIME_CACHE_VALID): T | null {
    key = this.PREFIX_KEY + key;
    const currentDate = Moment().unix();
    try {
      const json = this._localStorageService.getItem(key);
      if (json) {
        const data: SavedLocalData<T> = JSON.parse(json);
        if (data.savedAt + cacheForSc >= currentDate && data.savedAt <= currentDate) {
          return data.data;
        } else {
          this._localStorageService.removeItem(key);
        }
      }
    } catch (e) {
      this._localStorageService.removeItem(key);
    }
    this._logger.error('data not found or not valid');
    return null;
  }

  setData(key: string, data: any): void {
    key = this.PREFIX_KEY + key;
    const currentDate = Moment().unix();
    const savedLocalData: SavedLocalData<any> = {
      savedAt: currentDate,
      data: data
    }
    this._localStorageService.setItem(key, JSON.stringify(savedLocalData));
  }

  async cachePromise<T>(key: string, promise: Promise<T>): Promise<T> {
    const result = this.getData<T>(key);
    if (result) {
      this._logger.log('[Cache] taken: ' + key, result);
      return result;
    }
    const response = await promise;
    this.setData(key, response);
    this._logger.log('[Cache] saved: ' + key, response);
    return response;
  }

  clearIdStaked(id: number) {
    try {
      this._localStorageService.removeItem(this.PREFIX_KEY + this.PREFIX_STAKED_KEY + id);
    } catch (e) {
      this._logger.error('clearIdStaked: ' + id);
      this._logger.error(e);
    }
  }

  clearAll(): void {
    for (let key in localStorage){
      if (key.startsWith(this.PREFIX_KEY)) {
        console.log(key)
      }
    }
  }

}

export interface SavedLocalData<T> {
  savedAt: number;
  data: T
}
