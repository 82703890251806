import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {GameConfigStore} from "./gameconfig.store";
import {GameConfig} from "../../models/gameConfig";
import {environment} from "../../../../environments/environment";
import {toBoolean} from "../../utils/functions";


@Injectable({providedIn: 'root'})
export class GameConfigQuery extends Query<GameConfig> {

  get allConfig(): GameConfig {
    return this.getValue();
  }

  get regionsSorted(): string[] {
    const currentRegion = this.allConfig.region?.replace(/\s/g, "");
    if (!currentRegion) return environment.regions;
    return environment.regions.sort((a, b) => a.replace(/\s/g, "") === currentRegion ? -1 : 1);
  }

  get regionNoSpace(): string {
    return this.regionsSorted[0].replace(/\s/g, "");
  }

  constructor(protected store: GameConfigStore) {
    super(store);
  }

  updateData(data: any) {
    const musicVolume = (data.music_volume || data.music_volume === 0) ? parseFloat(data.music_volume.toString()) : null;
    const soundVolume = (data.sound_volume || data.sound_volume === 0) ? parseFloat(data.sound_volume.toString()) : null;
    const region = data.region ?? null;
    const particlesEnabled = (data.particles_enabled || data.particles_enabled === 0) ? toBoolean(data.particles_enabled) : null;

    const minmax = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
    const newData: any = {};
    if ((musicVolume || musicVolume === 0) && !isNaN(musicVolume)) newData.music_volume = minmax(musicVolume, 0, 1);
    if ((soundVolume || soundVolume === 0) && !isNaN(soundVolume)) newData.sound_volume = minmax(soundVolume, 0, 1);
    if (region) newData.region = region;
    if (particlesEnabled !== null) newData.particles_enabled = particlesEnabled;
    if (Object.keys(newData).length !== 0) {
      this.store.update(newData);
    }
  }

  updateRegion(region: string): void {
    this.store.update({region: region});
  }

  setShowPwaModal(show: boolean): void {
    this.store.update({show_pwa_modal: show});
  }

  reset(): void {
    this.store.update(
      {
        music_volume: 0.5,
        sound_volume: 0.5,
        show_pwa_modal: true,
        particles_enabled: true,
      });
  }

}
