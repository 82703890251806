import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fts-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  readonly links: {title: string, link: string}[] = [
    {title: "CHARACTERS", link: "/"},
    {title: "ASSETS", link: "/assets"},
    {title: "MARKETPLACE", link: "/market"}
  ]

  constructor() { }

  ngOnInit() {
  }

}
