import {Query} from '@datorama/akita';
import {SessionStore} from './session.store';
import {Injectable} from "@angular/core";
import {SessionInfo, WaxAccountInfo} from "../../models/waxAccountInfo";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class SessionQuery extends Query<WaxAccountInfo> {

  isLogged$ = this.select('addressETH').pipe(map(s => !!s));
  addressETH$ = this.select('addressETH');

  get addressETH(): string | null {
    return this.getValue().addressETH;
  }

  get isLogged(): boolean {
    return !!this.addressETH;
  }

  get firebaseJWT(): string | null {
    return this.getValue().firebaseJWT;
  }

  constructor(protected _sessionStore: SessionStore) {
    super(_sessionStore);
  }


}
