import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {LogService} from "../services/log.service";
import {SessionQuery} from "../stores/session/session.query";
import {Observable} from "rxjs";
import {ToastService, ToastType} from "../services/toast.service";
import {AuthService} from "../services/auth.service";

@Injectable() export class AppGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _log: LogService,
    private _auth: SessionQuery,
    protected _session: SessionQuery,
    protected _toastService: ToastService,
    private _authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLogged = this._session.isLogged;
    if (isLogged) {
      return true;
    }
    this._log.log('app guard, url: ' +  state.url + ', activated: ' + this._router.url);
    //this._toastService.open("Login first", ToastType.INFO);
    this._router.navigate(['login']);
    return false;
  }

}
