import {Store, StoreConfig} from '@datorama/akita';
import {Injectable} from "@angular/core";
import {GameConfig} from "../../models/gameConfig";


@Injectable({providedIn: 'root'})
@StoreConfig({name: 'gameconfig'})
export class GameConfigStore extends Store<GameConfig> {

  constructor() {
    super({
      music_volume: 1,
      sound_volume: 1,
      show_pwa_modal: true,
      particles_enabled: true,
    });
  }
}
