import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {LogService} from "../../core/services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SessionQuery} from "../../core/stores/session/session.query";
import {GameConfigQuery} from "../../core/stores/gameconfig/gameconfig.query";
import {SessionStore} from "../../core/stores/session/session.store";
import {FireService} from "../../core/services/fire.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {WaxChainService} from "../../core/services/wax-chain.service";
import {ToastService} from "../../core/services/toast.service";
import {ETHService} from "../../core/services/connector.service";
import {LocalStorageService} from "./local-storage.service";
import {CacheResponseService} from "./cache-response.service";
import {BaseService} from "../../core/services/base.service";
import {firstValueFrom} from "rxjs";
import {GetNFTsForOwnerResult, OwnedNft} from "../models/alchemy";
import {Character, getRaceFromAlchemyItem, STATUS_ASSET} from "../../core/models/ethResponse";


@Injectable({providedIn: 'root'})
export class AlchemyService extends BaseService {

  private readonly alchemyApiKey: string = environment.alchemyApiKey;

  private dropContract(): any {
    return environment.dropContract;
  }

  constructor(
    protected _http: HttpClient,
    protected _log: LogService,
    protected _snack: MatSnackBar,
    protected _session: SessionQuery,
    private _gameConfig: GameConfigQuery,
    private _sessionStore: SessionStore,
    private _fireService: FireService,
    private _fireStore: AngularFirestore,
    private _waxChainService: WaxChainService,
    private _toastService: ToastService,
    private _ETHService: ETHService,
    private _localStorageService: LocalStorageService,
    private _cacheResponseService: CacheResponseService
  ) {
    super(_http, _session, _log, _snack);
    this.basePath = environment.alchemyUrl;
  }

  async getNFTsForOwner(owner: string): Promise<OwnedNft[]> {
    const result: GetNFTsForOwnerResult = await firstValueFrom(this.get(`/nft/v3/${this.alchemyApiKey}/getNFTsForOwner?owner=${owner}&contractAddresses[]=${this.dropContract()}&pageSize=10000`));
    return result.ownedNfts;
  }

  async getCharacterOwned(owner: string): Promise<Character[]> {
    const result = await this.getNFTsForOwner(owner);
    return result.map(nft => {
      return {
        tokenId: nft.tokenId,
        title: nft.name,
        imageUrl: nft.raw?.metadata?.image,
        isInGame: false,
        status: STATUS_ASSET.IN_WALLET, //Math.random() > 0.5 ? STATUS_ASSET.IN_WALLET: STATUS_ASSET.IN_GAME,
        pending_type: undefined,
        mint: Number(nft.tokenId),
        race: getRaceFromAlchemyItem(nft),
        wax_address: undefined
      }
    });
  }

  async getNFTsMetadataBatch(tokenIds: string[] | number[], contract: string): Promise<any[]> {
    const body = {
      tokens: tokenIds.map(id => ({
        contractAddress: contract,
        tokenId: id.toString(),
        tokenType: 'ERC721'
      }))
    }
    let resultStaked: { nfts: any[] } = {nfts: []}
    if (tokenIds.length > 0) {
      resultStaked = await firstValueFrom(this.post(`/nft/v3/${this.alchemyApiKey}/getNFTMetadataBatch`, body))
    }
    return resultStaked.nfts;
  }

}

