import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from "@angular/common/http";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MomentPipe} from "../pipes/moment.pipe";
import {MaterialModule} from "./material.module";
import {SecondsToTimePipe} from "../pipes/secondsToTime.pipe";
import {SecondsToHoursPipe} from "../pipes/secondsToHours.pipe";
import {ObscureAddressPipe} from "../pipes/obscureAddress.pipe";
import {DashIfEmptyPipe} from "../pipes/dashIfEmpty.pipe";

const MODULES: any[] = [
  HttpClientModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  FormsModule,
  MaterialModule
];

const COMPONENTS = [
  MomentPipe,
  SecondsToHoursPipe,
  SecondsToTimePipe,
  ObscureAddressPipe,
  DashIfEmptyPipe,
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [CommonModule],
  exports: [
    ...MODULES,
    ...COMPONENTS,
  ],
  providers: [
  ]
})
export class UtilsModule { }
