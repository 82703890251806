import {Injectable} from "@angular/core";
import {SessionQuery} from "../stores/session/session.query";
import {SessionStore} from "../stores/session/session.store";
import {createWeb3Modal, defaultWagmiConfig} from '@web3modal/wagmi'
import {Web3Modal} from "@web3modal/wagmi/dist/types/src/client";
import {
  Address,
  disconnect,
  getAccount, getNetwork,
  readContract,
  signMessage, switchNetwork,
  waitForTransaction,
  watchAccount,
  watchContractEvent,
  writeContract,
  WriteContractResult
} from '@wagmi/core'
import {LogService} from "./log.service";
import {EthereumProvider} from "@walletconnect/ethereum-provider";
import * as Moment from 'moment';
import {abiDropContract, abiTheStakeContract} from "../interfaces/abitest";
import {ToastService, ToastType} from "./toast.service";
import {MatDialog} from "@angular/material/dialog";
import {SignModalComponent} from "../../shared/components/sign-modal/sign-modal.component";
import {CONFIRMATIONS_MAIN_TRANSACTION} from "../core.constants";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {SimpleSignModalComponent} from "../../shared/components/simple-sign-modal/simple-sign-modal.component";
import {CacheResponseService} from "../../shared/services/cache-response.service";
import {getNetworkId} from "../utils/functions";
import {getChainId} from "viem/actions";


@Injectable({providedIn: 'root'})
export class ETHService {

  web3Modal: Web3Modal;
  contract: any;
  config: any;
  publicContract: any;
  provider: any;

  dropContract: Address = environment.dropContract as any;
  stakeContract: Address = environment.stakeContract as any;

  get address(): string | null {
    return this._session.addressETH;
  }

  constructor(
    private _sessionStore: SessionStore,
    private _session: SessionQuery,
    private _logger: LogService,
    private _toastService: ToastService,
    private _dialog: MatDialog,
    private _router: Router,
    private _cacheResponseService: CacheResponseService
  ) {

    const projectId = '9108f1361c53c2ab50b7329dce3e4c37';

    const metadata = {
      name: 'Web3Modal',
      description: 'Web3Modal Example',
      url: 'https://web3modal.com',
      icons: ['https://avatars.githubusercontent.com/u/37784886']
    };

    //todo fix this
    if (!environment.production && environment.chainIsInTest) {

    }

    const chains = [getNetworkId()];
    const wagmiConfig = defaultWagmiConfig({chains, projectId, metadata});
    this.config = wagmiConfig;
    this.web3Modal = createWeb3Modal({wagmiConfig, projectId, chains});
    this.provider = new EthereumProvider();

    watchAccount(account => {
      if (account.isConnected) {
        const previousAddressETH = this._session.addressETH;
        this._sessionStore.update({addressETH: account.address.toString()});
        new Promise(resolve => setTimeout(resolve, 2000)).then(s => {
          this.readBalanceOf();
        })
        if (previousAddressETH !== account.address.toString()) {
          window.location.reload()
        }
        this.checkNetwork(true);
      } else {
        this._sessionStore.metamaskLogout();
      }
    })
  }

  private async checkNetwork(showToast: boolean = false) {
    if (getNetwork().chain.id !== getNetworkId().id) {
      try {
        await switchNetwork({chainId: getNetworkId().id})
      } catch (e) {
        console.error('switch network: ', e)
        const messageError = 'Error while switching networks. You need to switch to ' + getNetworkId().name;
        if (showToast) {
          this._toastService.open(messageError, ToastType.ERROR);
        }
        throw Error(messageError);
      }
    }
  }

  async connect(redirectToLogin: boolean = true): Promise<any> {
    if (getAccount().isConnected) {
      this.logout();
      this._cacheResponseService.clearAll();
      if (redirectToLogin) {
        this._router.navigate(['login']);
      }
    } else {
      return this.web3Modal.open();
    }
  }

  async readBalanceOf(): Promise<number> {
    const result = await readContract({
      address: this.dropContract,
      abi: abiDropContract,
      functionName: 'balanceOf',
      args: [this.address]
    });
    return Number(result);
  }

  async getUnstakingAssets(tokenIds: any): Promise<any> {
    const result = await readContract({
      address: this.stakeContract,
      abi: abiTheStakeContract,
      functionName: 'getUnstakingAssets',
      args: [tokenIds]
    });
    return result;
  }

  async getStakeInfo(): Promise<number[]> {
    const result = await readContract({
      address: this.stakeContract,
      abi: abiTheStakeContract,
      functionName: 'getStakeInfo',
      args: [this.address]
    });
    return JSON.parse(JSON.stringify(result, (key, value) =>
      typeof value === 'bigint'
        ? parseInt(value.toString(), 10)
        : value // return everything else unchanged
    ));
  }

  async isApprovedForAll(): Promise<boolean> {
    const result = await readContract({
      address: this.dropContract as Address,
      abi: abiDropContract,
      functionName: 'isApprovedForAll',
      args: [this.address, this.stakeContract]
    });
    return Boolean(result)
  }

  async signTransaction(dialog: SignModalComponent, array: any[]) {
    dialog.setTransactionsCount(array.length);
    let successCount = 0;
    const hashArray: string[] = [];
    try {
      await this.checkNetwork();
      for(let i = 0; i < array.length; i++) {
        const writeItem = array[i];
        const result = await writeContract(writeItem);
        dialog.setTransactionsSigned(i+1);
        const transactionReceipt = await waitForTransaction({
          chainId: getNetworkId().id,
          hash: result.hash,
          confirmations: Math.max(writeItem.confirmations || 0, CONFIRMATIONS_MAIN_TRANSACTION),
        })
        hashArray.push(result.hash);
        dialog.setTransactionsConfirmed(i+1);
        successCount++;
      }
    } catch (e) {
      this._toastService.open(e.shortMessage ?? e.message ?? e.error ?? 'Error transaction', ToastType.ERROR);
      this._logger.error('[signTransaction] error: ', e)
    }
    return {
      success: successCount == array.length,
      success_count: successCount,
      tot_count: array.length,
      hash_array: hashArray
    }
  }
  async signListTransaction(array: any[], descriptionModal: string = '') {
    const dialog = SimpleSignModalComponent.open(this._dialog, array.length, descriptionModal)
    let successCount = 0;
    const hashArray: string[] = [];
    try {
      for(let i = 0; i < array.length; i++) {
        const writeItem = array[i];
        const result = await writeContract(writeItem);
        dialog.componentInstance.setTransactionsSigned(i+1);
        const transactionReceipt = await waitForTransaction({
          chainId: getNetworkId().id,
          hash: result.hash,
          confirmations: Math.max(writeItem.confirmations || 0, CONFIRMATIONS_MAIN_TRANSACTION),
        })
        hashArray.push(result.hash);
        dialog.componentInstance.setTransactionsConfirmed(i+1);
        successCount++;
      }
    } catch (e) {
      this._toastService.open(e.shortMessage ?? e.message ?? e.error ?? 'Error transaction', ToastType.ERROR);
      this._logger.error('[signTransaction] error: ', e)
    }
    dialog.close();
    return {
      success: successCount == array.length,
      success_count: successCount,
      tot_count: array.length,
      hash_array: hashArray
    }
  }

  async writeContact(item: {contract: { address: string; abi: any }, functionName: string, args: any[], confirmations: number}): Promise<WriteContractResult> {
    const convertedItem: any = {
      abi: item.contract.abi,
      address: item.contract.address,
      ...item
    }
    delete convertedItem['contract'];
    try {
      return await writeContract(convertedItem);
    } catch (e) {
      this._toastService.open(e.shortMessage ?? e.message ?? e.error ?? 'Error transaction', ToastType.ERROR);
      throw e;
    }
  }

  watchEvent(address: Address, abi: any, eventName: string, callback: (logs: any) => void): () => void {
    return watchContractEvent({
      address: address,
      abi: abi,
      eventName: eventName,
    }, (logs) => {
      callback(logs);
      this._logger.log(`[${eventName}] New logs!`, logs);
    })
  }

  async readContract(address: Address, abi: any, functionName: string, args: any[]) {
    return await readContract({
      address: address,
      abi: abi,
      functionName: functionName,
      args: args
    });
  }

  async logout(): Promise<any> {
    this._sessionStore.metamaskLogout();
    await disconnect();
  }

  async signatureBody(): Promise<any> {
    const message = Moment().unix();
    const account = getAccount();
    const proof = await signMessage({message: message.toString()});
    const address = account.address;

    return {
      message,
      proof,
      address
    };
  }

}
