import {Component, Inject, OnInit} from '@angular/core';
import {SignModalComponent} from "../sign-modal/sign-modal.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ETHService} from "../../../core/services/connector.service";
import {abiDropContract, abiTheStakeContract} from "../../../core/interfaces/abitest";
import {BackendService} from "../../../core/services/backend.service";
import {ToastService, ToastType} from "../../../core/services/toast.service";
import {CONFIRMATIONS_MAIN_TRANSACTION, CONFIRMATIONS_SECONDARY_TRANSACTION} from "../../../core/core.constants";
import {Character, STATUS_ASSET} from "../../../core/models/ethResponse";
import {EthereumService} from "../../../core/services/ethereum.service";
import {StakingModalService} from "../../services/staking-modal.service";

@Component({
  selector: 'fts-stake-sign-modal',
  templateUrl: './stake-sign-modal.component.html',
  styleUrls: ['./stake-sign-modal.component.css', '../sign-modal/sign-modal.component.css']
})
export class StakeSignModalComponent extends SignModalComponent implements OnInit{

  isPreparingTransaction = false;
  character: Character;

  signList: any[] | undefined;

  get canShowRefresh(): boolean {
    return !this.error
  }

  progressBackend = 0;
  progressConfirmation = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Character,
    protected _dialog: MatDialog,
    protected _dialogRef: MatDialogRef<StakeSignModalComponent>,
    private _toastService: ToastService,
    protected _ETHService: ETHService,
    protected _backend: BackendService,
    private _ethereumService: EthereumService,
    protected _stakingModalService: StakingModalService,
  ) {
    super(_dialog, _dialogRef, _ETHService, _backend)
    if (!data) {
      _toastService.open('Character not found. Retry', ToastType.ERROR);
      _dialogRef.close();
    }
    this.character = data;
  }

  ngOnInit() {
    this.refresh();
  }

  get isRefreshing(): boolean {
    return this.isPreparingTransaction;
  }

  async refresh() {
    try {
      this.isPreparingTransaction = true;
      this.error = undefined;
      this.signList = undefined;
      if (this.character.status === STATUS_ASSET.PENDING) {
        await this.pendingRefresh();
        return;
      }
      const signList = [];
      const isApprovedForAll = await this._ETHService.isApprovedForAll();
      if (!isApprovedForAll) {
        signList.push({
          abi: abiDropContract,
          address: this._ETHService.dropContract,
          functionName: 'setApprovalForAll',
          args: [this._ETHService.stakeContract, 1],
          confirmations: CONFIRMATIONS_SECONDARY_TRANSACTION,
        })
      }
      signList.push({
        abi: abiTheStakeContract,
        address: this._ETHService.stakeContract,
        functionName: 'stake',
        args: [this.character.tokenId],
        confirmations: CONFIRMATIONS_MAIN_TRANSACTION,
      })
      this.signList = signList;
      this.stake(this.character, signList);
    } catch(e) {
      this.error = {message: 'Error while preparing transaction', callback: () => this.refresh()}
    } finally {
      this.isPreparingTransaction = false;
    }
  }

  async pendingRefresh() {
    try {
      const hash = await this._stakingModalService.getHashCurrentStaking(this.character);
      //const hash = await this._stakingModalService.getHashCurrentUnstaking(this.character);
      if (hash) {
        this.isPreparingTransaction = false;
        this.signList = [];
        this.transactionsCount = 1;
        this.setTransactionsSigned(1);
        this.setTransactionsConfirmed(1);
        this.currentStep = 1;
        await this.callBackend(hash)
      }
    } catch (e) {
      this._toastService.open('Can\'t retrieve your stake transaction', ToastType.ERROR);
    }
  }

  async stake(item: Character, signList: any[]): Promise<void> {
    const result = await this._ETHService.signTransaction(this, signList);
    if (!result?.success) {
      this._dialogRef.close();
      return;
    }
    const hashStake = result.hash_array[result.hash_array.length -1];
    this.callBackend(hashStake);
  }

  async callBackend(hashStake: string) {
    this.error = undefined;
    const resultBackend = await this._backend.stake(this.character.tokenId, hashStake, () => {
      this.progressBackend = 50;
    })
    if (!resultBackend.success) {
      this.error = {message: resultBackend.errorMessage, callback: () => this.callBackend(hashStake)}
      return;
    }
    this.progressBackend = 100;
    this.currentStep = 2;
    await new Promise(resolve => setTimeout(resolve, 2000));
    this.progressConfirmation = 100;
    this.currentStep = 3;
    await new Promise(resolve => setTimeout(resolve, 1500));
    this._dialogRef.close(true);
  }

}
