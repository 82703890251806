import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fts-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginButtonComponent {

  @Input() addressETH: string = ''
  @Input() showArrow = true;

  @Output() onMouseEnter = new EventEmitter();
  @Output() onClickAddress = new EventEmitter();
  @Output() onLogin = new EventEmitter();

}
