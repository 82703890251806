import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'secondsToTime'})
export class SecondsToTimePipe implements PipeTransform {

  transform(value: any, short: boolean = false, addSeconds: boolean = false): string {
    if (value === null || value === undefined) {
      return '--';
    }
    const days = Math.floor(value / 86400);
    let hours = Math.floor((value - (days * 86400)) / 3600);
    let minutes = Math.floor((value - (days * 86400) - (hours * 3600)) / 60);
    let timeString = '';
    if (short) {
      if (value === 86400) {
        timeString += "24h";
        return timeString;
      }
      //show only hours (add days to total hours)
      if (days > 0) {
        timeString += days + "d ";
      }
      timeString += hours + "h";
      if (minutes > 0 || addSeconds) {
        timeString += " " + (minutes < 10 ? '0' : '') + minutes + "m";
      }
      if (addSeconds) {
        const seconds = value - (days * 86400) - (hours * 3600) - (minutes * 60);
        timeString += " " + (seconds < 10 ? '0' : '') + seconds + "s";
      }
      return timeString;
    }
    if (days > 0) {
      timeString += days + ((days > 1) ? " days " : " day ");
    }
    timeString += (hours > 1) ? (hours + " hours") : (hours + " hour");
    if (minutes > 0) {
      timeString += " " + ((minutes > 1) ? (minutes + " minutes") : (minutes + " minute"));
    }
    return timeString;
  }

}
