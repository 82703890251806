import {catchError, map, share} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LogService} from './log.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SessionQuery} from "../stores/session/session.query";
import {AtomicAsset} from "../models/blockchain/atomicAsset";
import {environment} from "../../../environments/environment";
import {Template} from "../models/blockchain/atomicTemplates";


export class BaseService {

  protected basePath = '';

  constructor(
    protected _http: HttpClient,
    protected _session: SessionQuery,
    protected _log: LogService,
    protected _snack: MatSnackBar,
  ) {
  }

  get atomicApi(): string {
    return 'https://' + environment.atomicApi;
  }

  protected get<T>(path: string, preventError = false): Observable<any> {
    this._log.log('[GET]: ' + path);
    return this._http.get<T>(this.basePath + path).pipe(
      share(),
      catchError(response => {
        this._log.error(response);
        return preventError ? of([]) : throwError(response);
      }),
      map((response: any) => {
        this._log.log(response);
        return response && response.data ? response.data : response;
      })
    );
  }

  protected getFullUrl<T>(url: string, preventError = true, headers?: HttpHeaders): Observable<any> {
    return this._http.get<T>(url, {headers}).pipe(
      share(),
      catchError(response => {
        return preventError ? of([]) : throwError(response);
      }),
      map((response: any) => {
        this._log.log('[GET FULL]: ' + url);
        this._log.log(response);
        return response && response.data ? response.data : response;
      })
    );
  }


  protected post(path: string, body: any, headers?: HttpHeaders): Observable<any> {
    this._log.log('[POST]: ' + path);
    return this._http.post<any>(this.basePath + path, body, {headers}).pipe(
      share(),
      catchError(response => {
        this._log.error(response);
        return throwError(response.error);
      }),
      map((response: any) => {
        this._log.log(response);
        return response && response.data ? response.data : response;
      })
    );
  }

  protected getOwnedAssetsByTemplateIds(wallet: string, templates_ids: string[] | number[]): Observable<AtomicAsset[]> {
    if (!wallet || templates_ids.length === 0) return of([]);
    return this.getFullUrl(this.atomicApi + '/atomicassets/v1/assets' +
      '/?owner=' + wallet +
      '&container=1' +
      '&limit=1000' +
      '&template_id=' + templates_ids.slice(0, 1000000) +
      '&sort=asset_id', true
    );
  }

  protected getAssetsByIds(asset_ids: string[] | number[]): Observable<AtomicAsset[]> {
    if (asset_ids.length === 0) return of([]);
    let idString = '';
    asset_ids.forEach((id: any) => idString += id + ',');
    return this.getFullUrl(this.atomicApi + '/atomicassets/v1/assets' +
      '/?ids=' + idString.slice(0, -1) +
      '&limit=1000' +
      '&sort=asset_id', true
    );
  }

  protected getTemplatesByIds(templateIds: string[] | number[]): Observable<Template[]> {
    if (templateIds.length === 0) return of([]);
    let idString = '';
    templateIds.forEach((id: any) => idString += id + ',');
    return this.getFullUrl(this.atomicApi + '/atomicassets/v1/templates' +
      '?ids=' + idString.slice(0, -1) +
      '&limit=1000' +
      '&sort=created', true
    );
  }

}
