<div class="container full-width" fxLayout="column" fxLayoutAlign="center center">
  <span class="title">{{titleModal}}</span>
  <span class="subtitle" fxFlexOffset="10px">Please sign the transactions, it may take a few minutes!</span>
  <div class="container-steps" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center" fxFlexOffset="80px">
    <fts-step-circle
      [position]="0"
      [currentStep]="currentStep"
      [label]="'Transaction Signed'"
      [subtitle]="transactionsSigned + '/' + transactionsCount"
      [percentageProgress]="percentageTransaction"
    ></fts-step-circle>
    <fts-step-circle
      [position]="1"
      [currentStep]="currentStep"
      [label]="'Transaction Confirmed'"
      [subtitle]="transactionsConfirmed + '/' + transactionsCount"
      [percentageProgress]="0"
    ></fts-step-circle>
    <fts-step-circle
      [position]="2"
      [currentStep]="currentStep"
      [label]="'Waiting back-end'"
      [percentageProgress]="0"
    ></fts-step-circle>
    <fts-step-circle
      [position]="3"
      [currentStep]="currentStep"
      [label]="'Confirmation'"
      [showProgress]="false"
    ></fts-step-circle>
  </div>
  <div class="image-close" (click)="closeDialog()">
    <img src="/assets/icons/ic-close.svg" class="full-width" />
  </div>
</div>
