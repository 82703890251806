import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BaseService} from "./base.service";
import {SessionStore} from "../stores/session/session.store";
import {SessionQuery} from "../stores/session/session.query";
import {LogService} from "./log.service";
import {environment} from "../../../environments/environment";
import {GameConfigQuery} from "../stores/gameconfig/gameconfig.query";
import {FireService} from "./fire.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ETHService} from "./connector.service";
import {firstValueFrom} from "rxjs";
import {convertNumberToHex256} from "../utils/functions";
import {
  BaseAlchemyResult,
  BaseAlchemyTransfer,
  Character, getRaceFromAlchemyItem,
  PADDING_TYPE,
  STATUS_ASSET,
  UnstakingItem
} from "../models/ethResponse";
import {WaxChainService} from "./wax-chain.service";
import {ToastService, ToastType} from "./toast.service";
import {AuthWaxRow} from "../models/chainResponse";
import {AlchemyService} from "../../shared/services/alchemy.service";

@Injectable({providedIn: 'root'})
export class EthereumService extends BaseService {

  private authHeaders(): any {
    let headers = new HttpHeaders();
    /*headers = headers.set('Authorization', 'Bearer ' + this._fireService.getCurrentJWT());
    this._log.log(headers);*/
    return headers;
  }

  private readonly alchemyApiKey: string = environment.alchemyApiKey;

  constructor(
    protected _http: HttpClient,
    protected _log: LogService,
    protected _snack: MatSnackBar,
    protected _session: SessionQuery,
    private _gameConfig: GameConfigQuery,
    private _sessionStore: SessionStore,
    private _fireService: FireService,
    private _fireStore: AngularFirestore,
    private _waxChainService: WaxChainService,
    private _toastService: ToastService,
    private _eth: ETHService,
    private _alchemyService: AlchemyService
  ) {
    super(_http, _session, _log, _snack);
    this.basePath = environment.alchemyUrl;
  }

  async refreshJWTFirebase(): Promise<void> {
    //await this._fireService.refreshJWTFirebase(); //todo enable this row
  }

  async getUserAssets(): Promise<Character[]> {
    const address = this._session.addressETH;
    if (!address) {
      return [];
    }
    await this.refreshJWTFirebase();
    const ownedNFTs: Character[] = await this._alchemyService.getCharacterOwned(address)

    const stakedAssets = await this._eth.getStakeInfo();
    const body = {
      tokens: stakedAssets.map(id => ({
        contractAddress: environment.dropContract,
        tokenId: id.toString(),
        tokenType: 'ERC721'
      }))
    }
    let resultStaked: { nfts: any[] } = {nfts: []}
    if (stakedAssets.length > 0) {
      resultStaked = await firstValueFrom(this.post(`/nft/v3/${this.alchemyApiKey}/getNFTMetadataBatch`, body))
    }
    const list: Character[] = [];

    const stakedNFTs: Character[] = resultStaked.nfts.map(nft => {
        return {
          tokenId: convertNumberToHex256(parseInt(nft.tokenId, 10)),
          title: nft.name,
          imageUrl: nft.raw?.metadata?.image,
          isInGame: true,
          status: STATUS_ASSET.IN_GAME,
          pending_type: undefined,
          mint: Number(nft.tokenId),
          race: getRaceFromAlchemyItem(nft),
          wax_address: undefined
        }
      }
    );

    if (stakedNFTs.length > 0) {
      const result: AuthWaxRow[] = await this._waxChainService.getTable('g.polyverse', 'g.polyverse', 'auth', 10000);
      if (result.length === 0) {
        //todo manage error empty list for forEach find
        this._toastService.open('Error endpoint (wx). Refresh', ToastType.ERROR);
      }
      stakedNFTs.forEach(character => {
        const waxItem = result.find(row => row.pfp_id === character.mint);
        character.wax_address = waxItem.wax_address;
        if (waxItem.eth_address !== address) {
          character.status = STATUS_ASSET.PENDING
          character.pending_type = PADDING_TYPE.STAKE
        }
      })

      const unstakingAssets: UnstakingItem[] = await this._eth.getUnstakingAssets(stakedAssets);
      stakedNFTs.forEach(character => {
        const item = unstakingAssets.find(row => row.mint_id.toString() === character.mint.toString());
        if (!item) {
          this._toastService.open('character error wax: ' + character.mint, ToastType.ERROR);
        }
        if (item.isUnstaking) {
          character.status = STATUS_ASSET.PENDING
          character.pending_type = PADDING_TYPE.UNSTAKE
        }
      })
    }
    list.push(...ownedNFTs);
    list.push(...stakedNFTs)
    this._log.log('getUserAssets Success');
    return list;
  }

  async getLastStakeTransactions(): Promise<BaseAlchemyTransfer<any>> {
    const address = this._session.addressETH;
    if (!address) {
      this._toastService.open('account not logged', ToastType.ERROR);
      return undefined;
    }
    await this.refreshJWTFirebase();
    const requestBody = {
      id: 1,
      jsonrpc: "2.0",
      method: 'alchemy_getAssetTransfers',
      params: [
        {
          fromBlock: "0x0",
          toBlock: 'latest',
          fromAddress: address,
          toAddress: environment.stakeContract,
          withMetadata: true,
          excludeZeroValue: true,
          maxCount: "0x3e8",
          category: ['erc721']
        }
      ]
    }
    const result = await firstValueFrom(this.post(`/v2/${this.alchemyApiKey}`, requestBody, this.authHeaders()));
    this._log.log('getLastStakeTransactions Success');
    return result;
  }

  async getLastUnstakeTransactions(): Promise<BaseAlchemyTransfer<any>> {
    const address = this._session.addressETH;
    if (!address) {
      this._toastService.open('account not logged', ToastType.ERROR);
      return undefined;
    }
    await this.refreshJWTFirebase();
    const requestBody = {
      id: 1,
      jsonrpc: "2.0",
      method: 'alchemy_getAssetTransfers',
      params: [
        {
          fromBlock: "0x0",
          toBlock: 'latest',
          fromAddress: address,
          toAddress: environment.stakeContract,
          withMetadata: true,
          excludeZeroValue: true,
          maxCount: "0x3e8",
          category: ['external']
        }
      ]
    }
    const result = await firstValueFrom(this.post(`/v2/${this.alchemyApiKey}`, requestBody, this.authHeaders()));
    this._log.log('getLastUnstakeTransactions Success');
    return result;
  }

  async getTransactionInfoByHash(hash: string): Promise<BaseAlchemyResult<any>> {
    const requestBody = {
      id: 1,
      jsonrpc: "2.0",
      method: "eth_getTransactionByHash",
      params: [hash]
    }
    const result = await firstValueFrom(this.post(`/v2/${this.alchemyApiKey}`, requestBody, this.authHeaders()));
    this._log.log('getLastUnstakeTransactions Success');
    return result;
  }

}
