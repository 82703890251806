export const unityConfig = {
  dataUrl: "Unity/Build/poly.data.gz",
  frameworkUrl: "Unity/Build/poly.framework.js.gz",
  codeUrl: "Unity/Build/poly.wasm.gz",
  streamingAssetsUrl: "StreamingAssets",
  companyName: "DefaultCompany",
  productName: "test",
  productVersion: "1.0",
  loaderUrl: 'Unity/Build/poly.loader.js',
  devicePixelRatio: window.devicePixelRatio,
};
