import {Component, Input} from '@angular/core';
import {ToolbarComponent} from "../toolbar/toolbar.component";

@Component({
  selector: 'fts-toolbar-ext',
  templateUrl: './toolbar-ext.component.html',
  styleUrls: ['../toolbar/toolbar.component.scss', './toolbar-ext.component.css',]
})
export class ToolbarExtComponent extends ToolbarComponent {

  //redirect to login on logout
  @Input() redirectToLogin = false;
  @Input() links: { title: string, link: string }[] = []

  logout() {
    this.showMenu = false;
    this.login();
  }

  override async login(): Promise<void> {
    await this._auth.connect(this.redirectToLogin);
  }
}
