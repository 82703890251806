<div class="main-card relative" fxLayout="column" *ngIf="item">
  <img [src]="item.imageUrl" width="100%" class="image-asset">
  <div class="card-shade"></div>
  <div class="info-zone" fxLayout="column">
    <span class="card-name">{{item.title}}</span>
    <small>{{item.race}}</small>
    <button mat-button class="main-button" *ngIf="item.status !== STATUS_ASSET.PENDING; else paddingView">OPEN</button>
    <ng-template #paddingView>
      <button mat-button class="main-button">
        CONTINUE {{item.pending_type == PADDING_TYPE.STAKE ? 'STAKE' : 'UNSTAKE'}}</button>
    </ng-template>
  </div>
  <div class="badge-in-game" *ngIf="item.status !== STATUS_ASSET.IN_WALLET" [ngClass]="{'in-game' : item.status === STATUS_ASSET.IN_GAME}" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
    <img src="/assets/icons/ic-game.png" width="20" height="20" *ngIf="item.status === STATUS_ASSET.IN_GAME"/>
    <span>{{item.status === STATUS_ASSET.IN_GAME ? 'IN GAME' : 'PENDING...'}}</span>
  </div>
</div>
