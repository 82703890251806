import {Component, Input, OnInit} from '@angular/core';
import {Character, PADDING_TYPE, STATUS_ASSET} from "../../../../core/models/ethResponse";

@Component({
  selector: 'fts-character-card',
  templateUrl: './character-card.component.html',
  styleUrls: ['./character-card.component.scss']
})
export class CharacterCardComponent implements OnInit {

  @Input() item: Character | undefined;

  constructor() { }

  ngOnInit() {
  }

  readonly STATUS_ASSET = STATUS_ASSET;
  protected readonly PADDING_TYPE = PADDING_TYPE;
}
