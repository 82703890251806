<div id="toolbar" fxLayoutAlign="space-between center">
  <div class="wallet-zone" (click)="login()" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
    <img src="/assets/images/ic-character.png" height="24" width="24">
    <span *ngIf="(addressETH$ | async)">{{addressETH$ | async | obscureAddress}}</span>
  </div>
  <div fxLayout="row" class="navigation-zone" *ngIf="showLinks">
    <div class="menu-link" fxLayoutAlign="center center" routerLink="./" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">characters</div>
    <div class="menu-link" fxLayoutAlign="center center" routerLink="./assets" routerLinkActive="active">assets</div>
    <div class="menu-link" fxLayoutAlign="center center" routerLink="./market" routerLinkActive="active">marketplace</div>
  </div>
  <div class="coin-zone" *ngIf="(addressETH$ | async); else loginView">
    <img src="/assets/images/ic-coin.png" height="32" draggable="false">
    <span>---</span>
  </div>
  <ng-template #loginView>
    <button mat-button class="main-button button-login" fxFlexOffset="4px" (click)="login()">CONNECT WALLET</button>
  </ng-template>
</div>
