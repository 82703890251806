import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../core/services/auth.service';
import {unityConfig} from '../../../Unity/unity.config';
import {LogService} from '../../core/services/log.service';
import {GameConfigQuery} from "../../core/stores/gameconfig/gameconfig.query";
import {UnityService} from "../../shared/services/unity.service";
import {ToastService, ToastType} from "../../core/services/toast.service";
import {Router} from "@angular/router";
import {UnityData} from "../../core/models/waxAccountInfo";
import {animate, style, transition, trigger} from "@angular/animations";

declare function createUnityInstance(canvas: any, config: any, progress: any): any;

@Component({
  selector: 'fts-unity',
  templateUrl: './unity.component.html',
  styleUrls: ['./unity.component.scss'],
  providers: [UnityService],
  animations: [
    trigger(
      'leaveOpacityAnimation', [
        transition(':leave', [
          style({ opacity: 1}),
          animate('500ms 2.2s', style({opacity: 0}))
        ])
      ]
    )
  ],
})
export class UnityComponent implements OnInit, OnDestroy {

  isMobile = false;
  showPopupPWA = false;
  showLoading = true;

  container: any;
  walletStorageChecked = false;
  canvas: any;
  loadingBar: any;
  progressBarFull: any;
  mobileWarning: any;
  isLoading = false;

  widthProgress = '0%';
  unityData: UnityData | undefined;

  constructor(
    private _log: LogService,
    private _toastService: ToastService,
    private _gameConfig: GameConfigQuery,
    private _authService: AuthService,
    private _unityService: UnityService,
    private _router: Router,
  ) {
    this.unityData = this._router.getCurrentNavigation()?.extras?.state as UnityData
    if (!this.unityData) {
      _toastService.open('You need to select a PFP before entering the game', ToastType.ERROR);
      this._router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    const c = document.createElement("canvas");
    const gl = c.getContext("webgl");
    const gl2 = c.getContext("webgl2");
    if ((gl && gl.getExtension('WEBGL_compressed_texture_astc')) || (gl2 && gl2.getExtension('WEBGL_compressed_texture_astc'))) {
      this.showPopupPWA = this._gameConfig.allConfig.show_pwa_modal ?? true;
      this.isMobile = true;
    }

    this.container = document.querySelector('#unity-container');
    this.canvas = document.querySelector('#unity-canvas');
    this.loadingBar = document.querySelector('#unity-loading-bar');
    this.progressBarFull = document.querySelector('#unity-progress-bar-full');
    this.mobileWarning = document.querySelector('#unity-mobile-warning');

    this.loadingBar.style.display = 'block';
    const script = document.createElement('script');
    script.src = unityConfig.loaderUrl;

    script.onload = () => this.initializeInstance();
    window.addEventListener('message', (event) => this.eventHandler(event));
    document.body.appendChild(script);
  }

  private async initializeInstance(): Promise<void> {
    await this._authService.initFirebase();
    this.walletStorageChecked = true;
    this.progressBarFull.style.width = this.widthProgress = 10 + '%';
    try {
      const unityInstance = await createUnityInstance(this.canvas, unityConfig, (progress) => {
        progress = Math.min(progress * 3, 1);
        this.progressBarFull.style.width = this.widthProgress = (100 * progress * 0.9) + 10 + '%'
      });
      this._unityService.init(unityInstance, this.unityData);
      this.loadingBar.style.display = 'none';
      this.showLoading = false;
    } catch (e) {
      this._toastService.open(e?.message ?? 'Loading Error (Your browser does not support graphics API "WebGL 2")', ToastType.ERROR);
      console.error(e);
    }
  }

  eventHandler(event?: MessageEvent): void {
    const tag = event.data.type || '';
    this._log.log(event);
    switch (tag) {
      case 'GetUser':
        this._unityService.getUser(tag);
        break;
      case 'GetCraftData':
        this._unityService.getAssetsForCraft(tag);
        break;
      case 'CraftWeapon':
        this._unityService.craftWeapon(tag, event.data.data);
        break;
      case 'GetShop':
        this._unityService.getShop(tag);
        break;
      case 'BuyShop':
        this._unityService.buyShop(tag, event.data.data);
        break;
      case 'GetRerollData':
        this._unityService.getAssetsForReroll(tag);
        break;
      case 'Reroll':
        this._unityService.reroll(tag, event.data.data);
        break;
      case 'GetUserWeapons':
        this._unityService.getUserWeapons(tag);
        break;
      case 'GetUserBlueprints':
        this._unityService.getUserBlueprints(tag);
        break;
      case 'GetUserBoosters':
        this._unityService.getUserBoosters(tag);
        break;
      case 'GetUserSafebits':
        this._unityService.getUserSafebits(tag);
        break;
      case 'GetDismantleData':
        this._unityService.getAssetsForDismantle(tag);
        break;
      case 'Dismantle':
        this._unityService.dismantle(tag, event.data.data);
        break;
      case 'SetEquip':
        this._unityService.setEquip(tag, event.data.data);
        break;
      case 'CompleteTutorial':
        this._unityService.completeTutorial(tag, event.data.data);
        break;
      case 'ResetTutorial':
        this._unityService.resetTutorial(tag, event.data.data);
        break;
      case 'GetClaimableResources':
        this._unityService.getAirdropInfo(tag);
        break;
      case 'Airdrop':
        this._unityService.requestAirdrop(tag, event.data.data);
        break;
      case 'ClaimAirdrop':
        this._unityService.claimAirdrop(tag);
        break;
      case 'GetStatus':
        this._unityService.getStatus(tag);
        break;
      case 'StartGame':
        this._unityService.startGame(tag, event.data.data);
        break;
      case 'GetGameSettings':
        this._unityService.getGameSettings(tag);
        break;
      case 'SetGameSettings':
        this._unityService.setGameSettings(event.data.data);
        break;
      case 'SetServerRegion':
        this._unityService.setServerRegion(event.data.data);
        break;
      default:
    }
  }

  ngOnDestroy() {
    this._unityService.onDestroy();
  }

}
