<div fxLayout="column">
  <div class="modalToolbar" fxLayoutAlign="space-between center">
    <span>Import</span>
    <div mat-dialog-close>
      <img src="/assets/images/ic-close.png" height="40" class="close-button" draggable="false">
    </div>
  </div>

  <div fxLayout="column" class="modalContent">

  </div>

  <div class="modal-actions-zone" fxLayoutAlign="end center">
    <button mat-button class="main-button">CONTINUE</button>
  </div>


</div>
