<div class="bg-login"></div>
<div class="login-container no-scrollbar">
  <div id="particles-js">
  </div>
  <div class="full-size" fxLayout="column" fxLayoutAlign="center center">
    <img src="/assets/images/POLYVERSE.png" height="20" class="poly-logo">
    <img src="/assets/images/logo.png" height="400" class="poli-emblem" fxFlexOffset="5">
    <span class="welcome-message">Welcome to the Pre-Alpha Stage of The Shelter. We are thankful that you will be helping us find bugs and provide feedback to refine and finalize our Game.</span>
    <div fxFlexOffset="32px">
      <button mat-button class="main-button" (click)="doLogin()">CONNECT WALLET</button>
    </div>
  </div>
</div>
