import {Injectable} from "@angular/core";
import {BaseService} from "../../core/services/base.service";
import {HttpClient} from "@angular/common/http";
import {LogService} from "../../core/services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SessionQuery} from "../../core/stores/session/session.query";
import {SessionStore} from "../../core/stores/session/session.store";
import {WaxChainService} from "../../core/services/wax-chain.service";
import {BackendService} from "../../core/services/backend.service";
import {ToastService, ToastType} from "../../core/services/toast.service";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {GameConfig, GameConfigResponse} from "../../core/models/gameConfig";
import {GameConfigQuery} from "../../core/stores/gameconfig/gameconfig.query";
import {UnityData} from "../../core/models/waxAccountInfo";
import {UserInfo} from "../../core/models/crafting";
import {AutoUnsubscribe} from "ngx-auto-unsubscribe-decorator";


@Injectable()
export class UnityService extends BaseService {

  private readonly wrapperName = 'BridgeGameObject' //ClientStartup;

  @AutoUnsubscribe()
  name$ = this._session.addressETH$.pipe(map(name => {
    if (name && this.unityInstance) {
      //this.setUserAuth();
    }
    return name ? name : '';
  }));

  private unityInstance: any;
  unityData: UnityData | undefined;

  constructor(
    protected _http: HttpClient,
    protected _log: LogService,
    protected _snack: MatSnackBar,
    protected _session: SessionQuery,
    private _sessionStore: SessionStore,
    private _gameConfig: GameConfigQuery,
    private _waxChainService: WaxChainService,
    protected _backend: BackendService,
    private _toastService: ToastService,
  ) {
    super(_http, _session, _log, _snack);
    this.basePath = environment.waxEndpoint;
    this.name$.subscribe();
    /*if (!environment.production || !this.unityData) {
      this.unityData = {
        pfp_id: 5,
        waxAddress: 'bjb5bh.pv'
      }
      this.completeTutorial('getStatus', '');
    }*/
  }

  init(unityInstance: any, unityData: UnityData): void {
    this.unityInstance = unityInstance;
    this.unityData = unityData;
  }

  async setGameSettings(data: any): Promise<void> {
    this._gameConfig.updateData(data);
  }

  async setServerRegion(data: any): Promise<void> {
    const region = data.region_selected ?? data.region;
    if (region) {
      this._gameConfig.updateRegion(region);
    }
  }

  getGameSettings(tag: string) {
    this.loadDataForUnity(tag, () => {
      const config: GameConfig = this._gameConfig.allConfig;
      const regions = this._gameConfig.regionsSorted;
      const response: GameConfigResponse = {
        music_volume: config.music_volume,
        sound_volume: config.sound_volume,
        regions: regions,
      }
      return response;
    });
  }

  getUser(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getUserWithWeapons(this.unityData.waxAddress, this.unityData.pfp_id)
    ).then();
  }

  getShop(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getShop(this.unityData.waxAddress),
    ).then();
  }

  getAssetsForCraft(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getAssetsForCraft(this.unityData.pfp_id, this.unityData.waxAddress)
    ).then();
  }

  craftWeapon(tag: string, data: any) {
    this.loadDataForUnityAsync(tag,
      this._backend.craftWeapon(
        this.unityData.pfp_id,
        data.blueprint_asset_id,
        data.blueprint_template_id,
        data.booster_asset_id
      )).then();
  }

  getAssetsForReroll(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getAssetsForReroll(this.unityData.pfp_id, this.unityData.waxAddress)
    ).then();
  }

  getUserWeapons(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getUserWeapons(this.unityData.pfp_id, this.unityData.waxAddress)
    ).then();
  }

  getUserBlueprints(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getUserBlueprints(this.unityData.pfp_id, this.unityData.waxAddress)
    ).then();
  }

  getUserBoosters(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getUserBoosters(this.unityData.pfp_id, this.unityData.waxAddress)
    ).then();
  }

  getUserSafebits(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getUserSafebits(this.unityData.pfp_id, this.unityData.waxAddress)
    ).then();
  }

  reroll(tag: string, data: any) {
    this.loadDataForUnityAsync(tag,
      this._backend.reroll(
        this.unityData.pfp_id,
        data.weapon_asset_id,
        data.stat_name,
        data.shield_asset_id
      )).then();
  }

  getAssetsForDismantle(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getAssetsForDismantle(this.unityData.pfp_id, this.unityData.waxAddress)
    ).then();
  }

  dismantle(tag: string, data: any) {
    this.loadDataForUnityAsync(tag,
      this._backend.dismantle(
        this.unityData.pfp_id,
        data.weapon_asset_id ?? data.asset_id,
      )).then();
  }

  setEquip(tag: string, data: any) {
    this.loadDataForUnityAsync(tag,
      this._backend.setEquip(
        this.unityData.pfp_id,
        data
      )).then();
  }

  completeTutorial(tag: string, data: any) {
    this.loadDataForUnityAsync(tag,
      this._backend.completeTutorial(
        this.unityData.pfp_id,
        this.unityData.waxAddress
      )).then();
  }

  resetTutorial(tag: string, data: any) {
    this.loadDataForUnityAsync(tag,
      this._backend.resetTutorial(
        this.unityData.pfp_id
      )).then();
  }

  buyShop(tag: string, data: any) {
    this.loadDataForUnityAsync(tag,
      this._backend.buyShop(
        this.unityData.pfp_id,
        data.shop_id,
      )).then();
  }

  requestAirdrop(tag: string, data: any) {
    this.loadDataForUnityAsync(tag,
      this._backend.requestAirdrop(
        this.unityData.pfp_id,
        data.id_res,
      )).then();
  }

  getAirdropInfo(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._waxChainService.getAirdropInfo(this.unityData.waxAddress)
    ).then();
  }

  claimAirdrop(tag: string) {
    this.loadDataForUnityAsync(tag,
      this._backend.claimResource(
        this.unityData.pfp_id,
      )).then();
  }

  getStatus(tag: string) {
    this.loadDataForUnityAsync(
      tag,
      this._backend.getStatus(this.unityData.pfp_id, this.unityData.waxAddress),
      false
    ).then();
  }

  startGame(tag: string, data: any) {
    this.loadDataForUnityAsync(
      tag,
      this._backend.startGame(this.unityData.pfp_id, data)
    ).then();
  }


  //utils functions

  private async loadDataForUnity(tag: string, callback: () => any) {
    const promise = async (): Promise<any> => {
      return await callback();
    }
    await this.loadDataForUnityAsync(tag, promise())
  }

  private async loadDataForUnityAsync(tag: string, promise: Promise<any>, showToast: boolean = true) {
    const startTime = Date.now();
    let data;
    try {
      const response = await promise;
      data = {success: true, data: response};
    } catch (e) {
      this._log.error('[' + tag + '] error');
      this._log.error(e);
      if (showToast) {
        this._toastService.open(e.error ?? e.message ?? 'Error ' + tag, ToastType.ERROR);
      }
      data = {success: false};
    }
    if ((Date.now() - startTime) < 1000) { //deve passare almeno un secondo da quando unity ha chiamato
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    this.sendMessage('Response' + tag, JSON.stringify(data));
  }

  private sendMessage(functionName: string, params: any, wrapperName = this.wrapperName): void {
    this._log.log(functionName + ' -> ' + params);
    this.unityInstance.SendMessage(wrapperName, functionName, params);
  }

  onDestroy() {
    this.unityInstance.Quit(function() {
      console.log("[unity] quit done!");
    });
    this.unityInstance = null;

  }

}
