import {Store, StoreConfig} from '@datorama/akita';
import {Injectable} from "@angular/core";
import {WaxAccountInfo} from "../../models/waxAccountInfo";

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'session'})
export class SessionStore extends Store<WaxAccountInfo> {

  constructor() {
    super({
      addressWax: null,
      addressETH: null,
      mail: null,
      firebaseJWT: null,
    });
  }

  metamaskLogout(): void {
    this.update({addressWax: null, addressETH: null, mail: null});
  }

  clearAccount(): void {
    this.update({addressWax: null, addressETH: null, mail: null, firebaseJWT: null});
  }

}
