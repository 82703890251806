import {ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'fts-step-circle',
  templateUrl: './step-circle.component.html',
  styleUrls: ['./step-circle.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepCircleComponent implements OnChanges{

  @Input() position: number = 0;
  @Input() currentStep: number = 0;
  @Input() label: string = '';
  @Input() subtitle: string | undefined;
  @Input() percentageProgress: number = 0;
  @Input() showProgress = true;
  @Input() canShowRefresh = true;

  @HostBinding('class') class = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showProgress) {
      this.class = this.showProgress ? '' : 'no-progress';
    }
  }

}
