import {environment} from "../../environments/environment";

export const CONFIRMATIONS_MAIN_TRANSACTION: number = environment.confirmations_main_transaction
export const CONFIRMATIONS_SECONDARY_TRANSACTION: number = 1;
export const PERCENTAGE_DISMANTLE_RECEIVE = 0.2; //user receive 20%
export const CRAFT_COST_PROGRESSION = 0.2; //user receive 20%
export const MAX_MINT_PFP = 888;
export const PVERSE_TOKEN_PRECISION = 100;
export const PHASES_DROP_CONFIG: { title: string, subtitle: string }[] = [
  {
    title: 'GTD',
    subtitle: '7 May - 13.00 UTC'
  },
  {
    title: 'FCFS',
    subtitle: '7 May - 15.00 UTC'
  },
  {
    title: 'Public',
    subtitle: '7 May - 18.00 UTC'
  }
]
export const MAX_LEVEL_PFP = 50;
