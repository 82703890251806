import {Injectable} from "@angular/core";
import {SessionStore} from "../stores/session/session.store";
import {LogService} from "./log.service";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SessionQuery} from '../stores/session/session.query';
import 'firebase/auth';
import {first, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import firebase from "firebase/compat";

@Injectable({providedIn: 'root'})
export class FireService {

  private readonly authState: Observable<firebase.User | null>;

  constructor(
    private _http: HttpClient,
    private _log: LogService,
    private _snack: MatSnackBar,
    private _session: SessionQuery,
    private _sessionStore: SessionStore,
    private _firebaseAuth: AngularFireAuth,
  ) {
    this.authState = this._firebaseAuth.authState;
    this.authState.subscribe(user => {
        if (!user) {
          if (this._session.firebaseJWT) {
            this._sessionStore.clearAccount()
          }
        }
      },
      err => {
        this._snack.open(`${err.status} ${err.statusText} (${err.error.message})`, 'Please try again');
      });
  }

  async getCurrentUser(): Promise<firebase.User | any> {
    return await this._firebaseAuth.user.pipe(first()).toPromise();
  }

  async refreshJWTFirebase(): Promise<void> {
    await this.getJwtToken();
  }

  async anonymousLogin(): Promise<any> {
    const credentials = await this._firebaseAuth.signInAnonymously();
    const id = await credentials.user.getIdToken();
    this._sessionStore.update({firebaseJWT: id});
    return id;
  }

  async signIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
    try {
      const credential = await this._firebaseAuth.signInWithEmailAndPassword(email, password);
      this._log.log('[FireService] ' + email + ' credential: ', JSON.stringify(credential));
      return credential;
    } catch (e: any) {
      const message = e.message;
      if (message) {
        this._snack.open(message);
      }
      this._log.error('[FireService] error', e);
      throw e;
    }
  }

  async signUp(email: string, password: string): Promise<firebase.auth.UserCredential> {
    try {
      const credential = await this._firebaseAuth.createUserWithEmailAndPassword(email, password);
      this._log.log('[FireService] ' + email + ' credential: ', JSON.stringify(credential));
      return credential;
    } catch (e: any) {
      const message = e.message;
      if (message) {
        this._snack.open(message);
      }
      this._log.error('[FireService] error', e);
      throw e;
    }
  }

  async passwordResetEmail(email: string): Promise<void> {
    try {
      await this._firebaseAuth.sendPasswordResetEmail(email);
    } catch (e) {
      const message = e.message;
      if (message) {
        this._snack.open(message);
      }
      this._log.error('[FireService] error', e);
      throw e;
    }
  }

  async getJwtToken(forceRefresh?: boolean): Promise<string> {
    let user = await this._firebaseAuth.currentUser;
    if (!user) {
      await this.anonymousLogin();
      user = await this._firebaseAuth.currentUser;
    }
    if (!user) {
      throw Error('User not logged');
    }
    const jwt = await user.getIdToken(forceRefresh);
    this.saveJWT(jwt);
    return jwt
  }

  async logout(): Promise<void> {
    try {
      await this._firebaseAuth.signOut();
    } catch (e) {
      this._log.log('[FireService] signOut error exception', e)
    }
    this._sessionStore.clearAccount();
    location.reload();
  }

  getCurrentJWT(): string | null {
    return this._session.firebaseJWT;
  }

  saveJWT(jwt: string) {
    this._sessionStore.update({firebaseJWT: jwt})
  }
}
