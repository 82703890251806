import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ETHService} from "../../../core/services/connector.service";
import {BackendService} from "../../../core/services/backend.service";

@Component({
  selector: 'fts-sign-modal',
  templateUrl: './sign-modal.component.html',
  styleUrls: ['./sign-modal.component.css']
})
export class SignModalComponent {

  transactionsCount: number = 0;
  transactionsSigned: number = 0;
  transactionsConfirmed: number = 0;

  readonly titleModal = 'DON’T CLOSE OR RELOAD THE PAGE'

  currentStep = -1;

  error: { message: string, callback: () => void } | undefined;

  get percentageTransaction(): number {
    return ((this.transactionsSigned + this.transactionsConfirmed) / (this.transactionsCount * 2)) * 100;
  }

  setTransactionsCount(count: number) {
    this.transactionsCount = count;
  }
  setTransactionsSigned(count: number) {
    this.transactionsSigned = count;
    if (count == 1) {
      this.currentStep = 0;
    }
  }
  setTransactionsConfirmed(count: number) {
    this.transactionsConfirmed = count;
    if (count === this.transactionsCount) {
      this.currentStep = 1;
    }
  }

  constructor(
    protected _dialog: MatDialog,
    protected _dialogRef: MatDialogRef<SignModalComponent>,
    protected _ETHService: ETHService,
    protected _backend: BackendService,
  ) {
    _dialogRef.addPanelClass('sign-transact-modal')
    _dialogRef.disableClose = true
  }

  closeDialog() {
    this._dialogRef.close()
  }

}
