import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./pages/login/login.component";
import {HomeComponent} from "./pages/home/home.component";
import {CharactersComponent} from "./pages/characters/characters.component";
import {AssetsComponent} from "./pages/assets/assets.component";
import {MarketplaceComponent} from "./pages/marketplace/marketplace.component";
import {UnityComponent} from "./pages/unity/unity.component";
import {AppGuard} from "./core/guards/app.guard";

const routes: Routes = [
  {path: '', component: HomeComponent, children: [
      {path: '', component: CharactersComponent},
      {path: 'assets', component: AssetsComponent},
      {path: 'market', component: MarketplaceComponent},
    ],  canActivate: [AppGuard]},
  {path: 'game', component: UnityComponent,  canActivate: [AppGuard]},
  {path: 'mint', loadChildren: () => import('./pages/drop/modules/drop-page.module').then(m => m.DropPageModule)},
  {path: 'stake', loadChildren: () => import('./pages/stake/modules/stake-page.module').then(m => m.StakePageModule)},
  {path: 'login', component: LoginComponent},
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
